import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, NavLink, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { ThreeDots } from "react-loader-spinner";
import CustomPagination from "./CustomPagination";
import { apiClient } from "../api/apiClient";
import { FETCH_TICKET } from "../api/apiConstants";
import { Button } from "@material-tailwind/react";

const Tickets = () => {
  const navigate = useNavigate();

  const columns = [
    {
      name: (
        <p className="font-[700] text-[#060606] text-[20px] font-poppins">#</p>
      ),
      cell: (row, index) => index + 1,
    },
    {
      name: (
        <p className="font-[700] text-[#060606] text-[20px] font-poppins">
          Name
        </p>
      ),
      selector: (row) => row.name,
      grow: 1.5,
    },
    {
      name: (
        <p className="font-[700] text-[#060606] text-[20px] font-poppins">
          Type
        </p>
      ),
      selector: (row) => row.type,
    },
    {
      name: (
        <p className="font-[700] text-[#060606] text-[20px] font-poppins">
          Timestamp
        </p>
      ),
      selector: (row) => moment(row.created_At).format("Do, MMM YYYY"),
    },
    {
      name: (
        <p className="font-[700] text-[#060606] text-[20px] font-poppins">
          Status
        </p>
      ),
      cell: (row) => (
        <div className="my-2">
          {row.status === false ? (
            <button className="p-[12px] bg-[#FBD7D7] text-[14px] font-[600] text-[#B53434] ">
              Pending
            </button>
          ) : (
            <button className="p-[12px] bg-[#E2FBD7] text-[14px] font-[600] text-[#34B53A] px-[16px]">
              Solved
            </button>
          )}
        </div>
      ),
    },
    {
      name: (
        <p className="font-[700] text-[#060606] text-[20px] font-poppins">
          Action
        </p>
      ),
      cell: (row, index) => (
        <div className="flex gap-5">
          <div
            className="edit cursor-pointer"
            onClick={() => navigate(`/view-ticket/${row?._id}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
            >
              <path
                d="M25.7333 12.4003L20.0667 6.80033L21.9333 4.93366C22.4444 4.42255 23.0724 4.16699 23.8173 4.16699C24.5622 4.16699 25.1898 4.42255 25.7 4.93366L27.5667 6.80033C28.0778 7.31144 28.3444 7.92833 28.3667 8.65099C28.3889 9.37366 28.1444 9.9901 27.6333 10.5003L25.7333 12.4003ZM23.8 14.367L9.66667 28.5003H4V22.8337L18.1333 8.70033L23.8 14.367Z"
                fill="#060606"
              />
            </svg>
          </div>
        </div>
      ),
    },
  ];

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [page]);

  useEffect(() => {
    getallevents();
  }, []);

  const getallevents = async () => {
    setLoader(true);
    try {
      const result = await apiClient.get(FETCH_TICKET);

      if (
        result.status === 400 ||
        result.status === 404 ||
        result.status === 409 ||
        result.status === 500
      ) {
        setLoader(false);
        return console.log("no events available");
      }

      setLoader(false);
      setData(result.data.response.ticketArray?.reverse());
    } catch (error) {
      setLoader(false);
      console.log("Something went wrong while finding event");
      return console.log("event fetched failed");
    }
  };

  const pendingTicketsData = [
    {
      name: "Sudheer Kumar",
      type: "Payment Related",
      status: "pending",
    },
    {
      name: "Prathap",
      type: "Corporate Events",
      status: "pending",
    },
    {
      name: "Sudheer Kumar",
      type: "Manikanta",
      status: "pending",
    },
    {
      name: "Akhilesh",
      type: "Private Events",

      status: "pending",
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: "bold",
        border: "none",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",

        fontSize: "20px",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        fontWeight: "800",
        height: "75px",
      },
    },
    header: {
      style: {},
    },
    rows: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",
        backgroundColor: "transparent",

        marginBottom: "-1px",

        fontSize: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        fontWeight: "600",
        overflow: "hidden",
        height: "75px",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "10px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
        borderCollapse: "collapse",
      },
    },
    headCells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    cells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
  };

  return (
    <>
      {loader ? (
        <div className="w-full h-full   bg-[#F6F6F6]">
          <div className="w-full h-[400px] flex justify-center items-center">
            {" "}
            <ThreeDots
              height="80"
              width="130"
              radius="9"
              color="#0074be"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />{" "}
          </div>
        </div>
      ) : (
        <div className="w-full py-10  bg-[#F6F6F6]">
          <div
            className="mx-4 md:mx-6 pb-4 mb-4 bg-[#fff]"
            style={{
              boxShadow:
                "0px 0px 15px 0px #ddd, 0px 4px 16px rgba(0, 0, 0, 0.25) ",
            }}
          >
            <div className="bg-[#fff] px-4 md:px-8 flex flex-row justify-between items-center h-[72px] md:h-[90px] ">
              <h1 className="font-poppins text-[20px] md:text-[30px] text-[#060606] font-[600] leading-[24px] ">
                Tickets
              </h1>

              {/* <div className='flex md:gap-10 gap-2'>
            <button
              onClick={() => navigate("/addevent")}
              className='flex py-[8px] px-[14px] md:p-[16px] border-[1px] border-[#808080] gap-2 cursor-pointer justify-center items-center'>
              <p className='text-[14px] md:text-[16px] font-[700] font-poppins tracking-[1.76px] hidden md:block'>
                Create Event
              </p>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'>
                <path
                  d='M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.4217 2.75 18.8923 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.655 21.1083 7.11733 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z'
                  fill='#060606'
                />
              </svg>
            </button>
          </div> */}
            </div>

            <div className="">
              <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                pagination
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tickets;
