import React from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { Outlet } from 'react-router-dom'

const Globalroute = () => {
  return (
 
    <div className='flex flex-row h-screen w-screen overflow-hidden'>

      <Sidebar />

      <div className='flex-1 overflow-y-auto'>

        <Navbar />
      
      
        <Outlet />
     
        
      </div>



    </div>
    
  )
}

export default Globalroute