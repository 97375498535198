import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Icon } from "@iconify/react";
import { apiClient } from "../../api/apiClient";
import { ADD_PAINTINGS } from "../../api/apiConstants";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

import { ThreeDots } from "react-loader-spinner";
const AddShopItem = () => {
  const [selectImg, setSelectImg] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const Navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      summary: "",
      itemSold: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Item name is required"),
      description: Yup.string().required("Summary is required"),
      summary: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      console.log(values);
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("summary", values.summary);
      formData.append("itemSold", values.itemSold);
      if (selectImg) {
        formData.append("paintings", selectImg);
        console.log(selectImg);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
        },
      };

      try {
        const response = await apiClient.post(ADD_PAINTINGS, formData, config);

        setIsSubmitting(false);
        // Check response status and display appropriate toast message
        if (response.status === 200 || response.status === 201) {
          // Assuming successful operation if status is 200 (OK) or 201 (Created)
          toast.success("Product created successfully !");
          Navigate("/shop");
        } else {
          // Handle other successful statuses if necessary
          toast.info(
            "Form submitted but with a different status: " + response.status
          );
        }
      } catch (error) {
        toast.error("Error submitting form try again !!");

        setIsSubmitting(false);
      }
    },
  });

  return (
    <div className="bg-[#F6F6F6] py-[1rem] px-[1rem] lg:px-[2.5rem] lg:py-[4rem]">
      <div
        style={{ boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
        className="bg-[#fff] p-[1rem] lg:p-[1.7rem] flex flex-col gap-[1.5rem] lg:gap-[2.5rem]"
      >
        <div className="flex flex-row justify-between items-center font-poppins">
          <div className="flex gap-[1.5rem] sm:gap-[2rem] items-center">
            <div
              onClick={() => Navigate("/shop")}
              className="border border-solid border-[#808080] h-[2rem] lg:h-[3rem] w-[2rem] lg:w-[3rem] flex justify-center items-center cursor-pointer"
            >
              <Icon
                icon="ic:round-arrow-back-ios-new"
                className="text-[1.3rem] lg:text-[1.5rem]"
              />
            </div>
            <h1 className="text-[#060606] text-[1.25rem] lg:text-[2rem] leading-[1.5rem] font-[600]">
              Add Item
            </h1>
          </div>
          <button
            onClick={() => formik.handleSubmit()}
            disabled={isSubmitting}
            className="bg-[#060606] h-[2rem] w-[4.8rem] lg:h-[3.5rem] lg:w-[12rem] flex justify-center items-center text-[#F6F6F6] text-[0.8rem] leading-[1rem] font-bold"
          >
            {isSubmitting ? <ThreeDots size={20} color="#FFF" /> : "Submit"}
          </button>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 gap-[1.5rem]">
            <div className="flex flex-col gap-2">
              <input
                name="title"
                placeholder="Item Name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                style={{ border: "1px solid #808080" }}
                className="h-[4.5rem] w-full p-[1rem] lg:p-[1.5rem] outline-none font-poppins placeholder:text-[#060606] placeholder:text-[1rem] placeholder:font-semibold"
              />
              {formik.touched.title && formik.errors.title && (
                <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                  {formik.errors.title}
                </div>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <input
                name="summary"
                placeholder="Summary"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.summary}
                style={{ border: "1px solid #808080" }}
                className="w-full p-[1rem] lg:p-[1.5rem] outline-none font-poppins placeholder:text-[#060606] placeholder:text-[1rem] placeholder:font-semibold"
              />
              {formik.touched.summary && formik.errors.summary && (
                <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                  {formik.errors.summary}
                </div>
              )}
            </div>

            <div className="flex flex-col gap-[1rem]">
              <div className="flex flex-col gap-2">
                <textarea
                  name="description"
                  rows="6"
                  placeholder="Description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  style={{ border: "1px solid #808080" }}
                  className="w-full p-[1rem] lg:p-[1.5rem] outline-none font-poppins placeholder:text-[#060606] placeholder:text-[1rem] placeholder:font-semibold"
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                    {" "}
                    {formik.errors.description}
                  </div>
                )}
              </div>
            </div>

            <div
              style={{ border: "1px solid  #808080" }}
              className="flex justify-between items-center  px-[1rem] py-[1rem] lg:p-[1.5rem]"
            >
              <div className="flex flex-row  justify-center gap-3 xl:gap-[8rem]">
                {selectImg?.name && (
                  <img
                    src={URL.createObjectURL(selectImg)}
                    alt=""
                    className="w-[4rem] h-[2rem] lg:h-[4rem] lg:w-[8rem] rounded-[5px]"
                  />
                )}

                <div className="flex items-center gap-[0.5rem] xl:gap-[1rem]">
                  {selectImg?.name ? (
                    <>
                      <h3 className="text-[0.7rem] lg:text-[1.2rem] text-black font-[700] ">
                        {selectImg.name.length > 30
                          ? selectImg.name.substring(0, 5) + "..."
                          : selectImg.name}
                      </h3>
                      <Icon
                        onClick={() => setSelectImg(null)}
                        icon="akar-icons:cross"
                        className="text-[0.8rem] lg:text-[1.5rem] cursor-pointer hover:bg-[#3D4A5C]/[0.3]"
                      />
                    </>
                  ) : (
                    <h3 className="text-[1rem] text-[#060606] font-bold font-poppins">
                      Upload Image
                    </h3>
                  )}
                </div>
              </div>
              <button
                type="button"
                className="text-[0.8rem] font-semibold text-white bg-[#060606] py-[0.5rem] px-[2rem] lg:py-[1rem] lg:px-[3rem]"
                onClick={() => document.querySelector(".input-field").click()}
              >
                Browse
              </button>
              <input
                type="file"
                accept="image/*"
                className="input-field"
                hidden
                onChange={(event) => {
                  if (event.currentTarget.files[0]) {
                    setSelectImg(event.currentTarget.files[0]);
                  }
                }}
              />
            </div>
          </div>
          {/* <button type="submit" className="bg-[#060606] h-[2rem] w-[4.8rem] text-[0.8rem] lg:h-[3.5rem] lg:w-[12rem] text-[#F6F6F6] leading-[1rem] font-bold">
                        Create
                    </button> */}
        </form>
      </div>
    </div>
  );
};

export default AddShopItem;
