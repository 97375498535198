// api.js
import axios from 'axios';

let BASE_URL = '';



const type  = "PROD";

if(type === "PROD"){

  BASE_URL = "https://apiv2.flodesign.in/"
}

else { 

  BASE_URL = "http://localhost:4000"
}

export const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // You can add additional headers here if needed
  },
});

// Function to make a GET request
export const fetchData = async (endpoint) => {
  try {
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// Function to make a POST request
export const postData = async (endpoint, data) => {
  try {
    const response = await apiClient.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
