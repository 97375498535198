import { useState } from "react";
import { useNavigate } from "react-router-dom";

import flodesignlogo from "../../assets/flodesignlogo.svg";
import vectortopdesktop from "../../assets/vectortopdesktop.svg";
import vectortopmobile from "../../assets/vectortopmobile.svg";
import { useFormik } from "formik";
import loginSchema from "../../validationSchema/loginSchema";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../Firebase";
import { Firebase_login_error } from "../../utility/Firebase_erros";
import { ThreeDots } from "react-loader-spinner";

function ForgotPasswordComponent() {
  return (
    <div className='w-full md:w-[512px] px-4 flex flex-col items-center self-center gap-[1rem] '>
      <h1 className='text-[1.5rem] font-[700] text-[#000] text-center'>
        Forgot Password?
      </h1>
      <p className='text-[#7F7F7F]  text-[1.3rem] font-[500] tracking-tighter '>
        Please enter your email address to receive a link
      </p>

      <div className='h-[56px] md:h-[60px] w-full flex flex-row items-center border border-solid border-[#808080]'>
        <div className='w-[13%] h-full flex flex-row justify-end items-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'>
            <path
              d='M20 8L12 13L4 8V6L12 11L20 6M20 4H4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V6C22 5.46957 21.7893 4.96086 21.4142 4.58579C21.0391 4.21071 20.5304 4 20 4Z'
              fill='black'
            />
          </svg>
        </div>

        <input
          type='email'
          placeholder='Email'
          className='h-full  w-full outline-none placeholder-[#808080] p-[1rem] placeholder:text-[16px] font-poppins font-[500]'
        />
      </div>

      <button className='h-[48px] bg-[#060606] w-full font-[1rem] text-[#F6F6F6] leading-[99%] font-[700] '>
        Send Email
      </button>
    </div>
  );
}

const LoginPage = () => {
  const [forgotPasswordStatus, setPasswordStatus] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);

  const Navigate = useNavigate();

  const { login } = useAuth();

  const { values, handleChange, handleSubmit, errors, touched, resetForm } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },

      validationSchema: loginSchema,

      onSubmit: async (values) => {
        console.log(values);
        setIsLoading(true);
        try {
          const confirmationResult1 = await signInWithEmailAndPassword(
            auth,
            values.email,
            values.password
          );
          console.log(confirmationResult1);
          toast.success("Logged in Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsLoading(false);
          Navigate("/");
        } catch (error) {
          setIsLoading(false);
          console.log("errior", error);
          toast.error(Firebase_login_error(error), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      },
    });

  console.log(forgotPasswordStatus);

  return (
    <div className='flex flex-col xl:h-screen overflow-hidden relative'>
      <div className='flex flex-row justify-end w-full h-auto'>
        <div className='h-[249px] w-[250px] md:h-[368px] md:w-[365px] xl:hidden'>
          <img src={vectortopmobile} className='h-full w-full ' />
        </div>

        <div className='hidden xl:block'>
          <img src={vectortopdesktop} className='h-full w-full' />
        </div>
      </div>

      <div className='w-full flex flex-col items-center  md:mt-[-150px] xl:mt-[-27%]  shrink-0'>
        {forgotPasswordStatus ? (
          <ForgotPasswordComponent />
        ) : (
          <div className='w-full md:w-[512px] px-4 flex flex-col items-center self-center  gap-[1rem] '>
            <div className='h-[80px] w-[180px] mb-[1rem] self-center'>
              <img src={flodesignlogo} className='w-full h-full ' />
            </div>

            <form onSubmit={handleSubmit} className='self-start px-4 w-full'>
              <div className='flex flex-col  gap-[1rem]'>
                <div className='flex flex-col gap-1'>
                  <div
                    style={{ border: "1px solid  #808080" }}
                    className='h-[56px] w-full  flex flex-row '>
                    <div className='w-[20%] pl-[1rem] h-full flex justify-center items-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'>
                        <path
                          d='M12 4C13.0609 4 14.0783 4.42143 14.8284 5.17157C15.5786 5.92172 16 6.93913 16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12C10.9391 12 9.92172 11.5786 9.17157 10.8284C8.42143 10.0783 8 9.06087 8 8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4ZM12 14C16.42 14 20 15.79 20 18V20H4V18C4 15.79 7.58 14 12 14Z'
                          fill='#060606'
                        />
                      </svg>
                    </div>

                    <input
                      type='text'
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                      placeholder='Username'
                      className='placeholder:text-[#808080] placeholder:text-[16px] placeholder:font-[500] placeholder:font-poppins w-full h-full outline-none'
                    />
                  </div>
                  <div>
                    {errors.email && touched.email ? (
                      <div className='error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]'>
                        *{errors.email}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className='flex flex-col gap-1'>
                  <div
                    style={{ border: "1px solid  #808080" }}
                    className='h-[56px] w-full  flex flex-row '>
                    <div className='w-[20%] pl-[1rem] h-full flex justify-center items-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'>
                        <path
                          d='M12 17C12.5304 17 13.0391 16.7893 13.4142 16.4142C13.7893 16.0391 14 15.5304 14 15C14 14.4696 13.7893 13.9609 13.4142 13.5858C13.0391 13.2107 12.5304 13 12 13C11.4696 13 10.9609 13.2107 10.5858 13.5858C10.2107 13.9609 10 14.4696 10 15C10 15.5304 10.2107 16.0391 10.5858 16.4142C10.9609 16.7893 11.4696 17 12 17ZM18 8C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V10C4 9.46957 4.21071 8.96086 4.58579 8.58579C4.96086 8.21071 5.46957 8 6 8H7V6C7 4.67392 7.52678 3.40215 8.46447 2.46447C9.40215 1.52678 10.6739 1 12 1C12.6566 1 13.3068 1.12933 13.9134 1.3806C14.52 1.63188 15.0712 2.00017 15.5355 2.46447C15.9998 2.92876 16.3681 3.47995 16.6194 4.08658C16.8707 4.69321 17 5.34339 17 6V8H18ZM12 3C11.2044 3 10.4413 3.31607 9.87868 3.87868C9.31607 4.44129 9 5.20435 9 6V8H15V6C15 5.20435 14.6839 4.44129 14.1213 3.87868C13.5587 3.31607 12.7956 3 12 3Z'
                          fill='#060606'
                        />
                      </svg>
                    </div>

                    <input
                      type='password'
                      placeholder='password'
                      name='password'
                      value={values.password}
                      onChange={handleChange}
                      className='placeholder:text-[#808080] placeholder:text-[16px] placeholder:font-[500] placeholder:font-poppins w-full h-full outline-none'
                    />
                  </div>

                  <div>
                    {errors.password && touched.password ? (
                      <div className='error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]'>
                        *{errors.password}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <button
                onClick={() => setPasswordStatus(true)}
                className='bg-transparent border-none w-full flex flex-row justify-end'>
                <span className='text-[12px] md:text-[15px] font-[600] leading-6  tracking-tight  '>
                  Forgot Password?
                </span>
              </button>

              <button
                type='submit'
                disabled={IsLoading}
                className='h-[48px] bg-[#060606] w-full mt-[1rem] shrink-0  text-[16px] text-[#F6F6F6] leading-[99%] font-[700] self-stretch relative flex items-center justify-center'>
                {IsLoading ? (
                  <div className='absolute inset-0 flex items-center justify-center'>
                    <ThreeDots color='#fff' />
                  </div>
                ) : (
                  "Login"
                )}
              </button>
            </form>
          </div>
        )}
      </div>

      <div className='h-[27.2rem] w-[28rem]  relative xl:fixed xl:bottom-0 '>
        <div className='bg-[#0074BE] h-full w-[90%] rounded-full absolute left-[-50%] xl:left-[-45%] top-[50%] overflow-hidden bottom-0 p-10'>
          <div className='h-full w-full bg-transparent border-[30px] border-[#fff] border-solid rounded-full   '></div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;