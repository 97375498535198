import { object, string, number, date, InferType,mixed } from "yup";

const AddEventSchemas = object({
 
    title: string().required("Title is required !"),
    description: string().required("descriptionis required !"),
    location: string().required("location field is required !"),
    expiryDate: mixed().required("expiryDatefield is required !"),
    price: string().required("price field is required !"),
    type: string().required("type field is required !"),
   

});

export default AddEventSchemas;
