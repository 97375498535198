
export const firebaseOTP_error = (error) => { 

    let errorMessage;
    switch (error.code) {
      case "auth/invalid-verification-code":
        errorMessage = "Invalid verification code";
        break;
      case "auth/code-expired":
        errorMessage = "Verification code has expired";
        break;
      case "auth/invalid-verification-id":
        errorMessage = "Invalid verification ID";
        break;
      case "auth/missing-verification-code":
        errorMessage = "Missing verification code";
        break;
      case "auth/quota-exceeded":
        errorMessage = "SMS quota for the project has been exceeded";
        break;
      case "auth/maximum-requests-reached":
        errorMessage = "Maximum number of verification attempts exceeded";
        break;
      default:
        errorMessage = "Error submitting OTP";
        break;
    }

    return errorMessage;
}


export const Firebase_login_error = (error) => {
    let errorMessage;
    switch (error.code) {
      case "auth/invalid-email":
        errorMessage = "Invalid email address";
        break;
      case "auth/user-not-found":
        errorMessage = "User not found";
        break;
      case "auth/wrong-password":
        errorMessage = "Incorrect password";
        break;
      case "auth/user-disabled":
        errorMessage = "User account is disabled";
        break;
      case "auth/invalid-login-credentials":
        errorMessage = "The login credentials Does not match";
        break;
      default:
        errorMessage = "Authentication error";
        break;
    }
  
    return errorMessage;
  };
  