import { useState } from "react";

const QuotationCard = () => {
  return (
    <div className='bg-[#F6F6F6] py-[1rem] px-[1rem] lg:px-[2.5rem] lg:py-[4rem]'>
      <div
        style={{ boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
        className='bg-[#fff] p-[1rem] lg:p-[1.7rem] flex flex-col gap-[1.5rem] lg:gap-[2.5rem]'>
        <div className='flex flex-row justify-between items-center font-poppins'>
          <h1 className='text-[#060606] text-[1.25rem] lg:text-[2rem] leading-[1.5rem] font-[600]'>
            Send Quotation
          </h1>

          <button className='bg-[#060606] h-[2rem] w-[4.8rem] text-[0.8rem] lg:h-[4rem] lg:w-[12rem] text-[#F6F6F6] leading-[1rem] font-bold'>
            Send
          </button>
        </div>
        <form>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-[1.5rem]'>
            <div className='flex flex-col gap-2'>
              <div style={{ border: "1px solid #808080" }}>
                <select
                  style={{ borderRight: "20px solid transparent" }}
                  className='h-[4.5rem] w-full p-[1rem] lg:p-[1.5rem] border-none  outline-none font-poppins placeholder:text-[#060606] placeholder:text-[1rem] placeholder:font-medium'
                  placeholder='Select Item'>
                  <option
                    className='font-poppins text-[#808080] text-[1rem]'
                    value=''
                    disabled
                    selected>
                    Item Name
                  </option>
                </select>
              </div>
            </div>
            <div className='flex flex-col gap-2'>
              <input
                placeholder='Price'
                type='number'
                style={{ border: "1px solid #808080" }}
                className='h-[4.5rem] w-full p-[1rem] lg:p-[1.5rem] outline-none font-poppins placeholder:text-[#808080] placeholder:text-[1rem] placeholder:font-medium'
              />
            </div>

            <div className='flex flex-col gap-[1rem] md:col-span-2'>
              <div className='flex flex-col gap-2'>
                <textarea
                  rows='6'
                  placeholder='Description'
                  style={{ border: "1px solid #808080" }}
                  className=' w-full p-[1rem] lg:p-[1.5rem] outline-none  font-poppins placeholder:text-[#808080] placeholder:text-[1rem] placeholder:font-medium'
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuotationCard;
