import { useState } from "react";
import { Icon } from "@iconify/react";
import { Input } from "@material-tailwind/react";

import profile from "../assets/profile.png";

import edit from "../assets/edit.svg";

const ProfileSection = () => {
  const [passwordShown, setPasswordStatus] = useState(false);
  return (
    <div className='bg-[#F6F6F6] w-full  px-4 py-10 lg:px-[40px] lg:py-[4rem]'>
      <div
        style={{ boxShadow: " 0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
        className='bg-[#fff] p-[1rem] lg:p-[1.8rem] flex flex-col gap-[1rem] '>
        <form className='flex flex-col gap-[1rem] lg:gap-[2.5rem]'>
          <div className=' flex flex-row justify-between items-center '>
            <h1 className='text-[#060606] text-[20px] text-poppins font-[700] leading-[1rem] md:text-[30px] '>
              Profile
            </h1>
            <button
              type='submit'
              className='bg-[#060606] h-[32px] w-[79px] md:h-[60px] md:w-[180px] text-[16px] text-[#F6F6F6] leading-[99%] font-[700] '>
              Save
            </button>
          </div>
          <div className='flex flex-col gap-[1.5rem] lg:gap-[3rem]'>
            <div className='flex flex-col gap-[1.5rem] lg:flex lg:flex-row lg:gap-[2rem] '>
              <div className='flex flex-col gap-[1rem] shrink-0 lg:w-[264px] lg:h-full   lg:gap-[1.4rem]'>
                <img src={profile} className='w-full sm:h-[300px] lg:h-[264px]  ' />

                <button className='bg-[#060606] h-[56px] w-full  text-[#F6F6F6] flex flex-row items-center justify-center gap-[10px] px-[1.5rem] py-[1rem]'>
                  <Icon icon='ic:baseline-edit' className='text-[1.5rem]' />
                  <p className='font-poppins text-[1rem] font-[700] tracking-[1.75px] text-[]'>
                    Edit Profile Picture
                  </p>
                </button>
              </div>

              <div className='flex flex-col gap-[1.5rem] w-full  lg:justify-between'>
                <div className='flex flex-col gap-[1rem] w-full'>
                  <div className='relative'>
                    <input
                      type='text'
                      id='name'
                      className='h-[4.5rem]  lg:h-[5rem]  p-[1rem] lg:px-[1.5rem] w-full  font-poppins text-[#060606] text-[1.2rem] font-[500]  peer pt-4 pb-2 px-2 border border-solid  border-[#808080] placeholder-transparent outline-none  block '
                    />
                    <label
                      htmlFor='name'
                      className='absolute left-2 -top-2.5 text-[#060606] text-[1rem] font-[600] bg-white px-1 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:left-2 peer-placeholder-shown:text-base  peer-focus:-top-2.5 peer-focus:left-2 peer-focus:text-[1rem] '>
                      Name
                    </label>
                  </div>
                </div>

                <div className='flex flex-col gap-[1rem] w-full'>
                  <div className='relative '>
                    <input
                      type='email'
                      id='email'
                      className='h-[4.5rem] lg:h-[5rem]  p-[1rem] lg:px-[1.5rem] w-full font-poppins text-[#060606] text-[1.2rem] font-[500]  peer pt-4 pb-2 px-2 border border-solid  border-[#808080] placeholder-transparent outline-none  block '
                    />
                    <label
                      htmlFor='email'
                      className='absolute left-2 -top-2.5 text-[#060606] text-[1rem] font-[600] bg-white px-1 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:left-2 peer-placeholder-shown:text-base  peer-focus:-top-2.5 peer-focus:left-2 peer-focus:text-[1rem] '>
                      Email
                    </label>
                  </div>
                </div>
                <div className='flex flex-col gap-[1rem] w-full'>
                  <div className='relative '>
                    <input
                      type='date'
                      id='dateOfBirth'
                      className='h-[4.5rem] lg:h-[5rem]  p-[1rem] lg:px-[1.5rem] w-full font-poppins text-[#060606] text-[1.2rem] font-[500]  peer pt-4 pb-2 px-2 border border-solid  border-[#808080] placeholder-transparent outline-none  block '
                    />
                    <label
                      htmlFor='dateOfBirth'
                      className='absolute left-2 -top-2.5 text-[#060606] text-[1rem] font-[600] bg-white px-1 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:left-2 peer-placeholder-shown:text-base  peer-focus:-top-2.5 peer-focus:left-2 peer-focus:text-[1rem] '>
                      Date of Birth
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-[1rem]'>
              <div className='relative '>
                <input
                  type='number'
                  id='mobileNumber'
                  className='h-[4.5rem] w-full p-[1rem] lg:px-[1.5rem] font-poppins text-[#060606] text-[1.2rem] font-[500]  peer pt-4 pb-2 px-2 border border-solid  border-[#808080] placeholder-transparent outline-none  block '
                />
                <label
                  htmlFor='mobileNumber'
                  className='absolute left-2 -top-2.5 text-[#060606] text-[1rem] font-[600] bg-white px-1 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:left-2 peer-placeholder-shown:text-base  peer-focus:-top-2.5 peer-focus:left-2 peer-focus:text-[1rem] '>
                  Mobile Number
                </label>
              </div>
            </div>

            <div className='flex flex-row justify-between relative  gap-[1rem]   md:mb-5 px-[1rem] items-center mb-3 h-[72px] w-full border border-solid border-[#808080]  '>
              <input
                type={passwordShown ? "text" : "password"}
                value={"*******"}
                className='border-none bg-transparent h-full w-full outline-none'
                // className={`${
                //   passwordShown ? "block" : "hidden"
                // } w-full input-field`}
              />

              <div className='flex flex-row justify-end  shrink-0 w-auto h-[28px] xl:h-[40px] xl:w-[140px]'>
                <button
                  type='button'
                  onClick={() => setPasswordStatus(true)}
                  // onClick={() => document.querySelector(".input-field").click()}
                  className='text-[12px] w-full flex flex-row justify-center items-center  bg-[#060606] xs:text-[0.9rem] text-white px-[0.8rem] xs:px-[1rem] py-[0.5rem]'>
                  Change Password
                </button>
              </div>
              <label
                htmlFor='mobileNumber'
                className='absolute left-2 -top-2.5 text-[#060606] text-[1rem] font-[600] bg-white px-1 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:left-2 peer-placeholder-shown:text-base  peer-focus:-top-2.5 peer-focus:left-2 peer-focus:text-[1rem] '>
                Password
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileSection;
