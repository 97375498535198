import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { ThreeDots } from 'react-loader-spinner'

const Reports = () => {

    const [eventdata, seteventData] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getallevents();
    }, [])


    const getallevents = async () => {

        setLoader(true)
        try {

            const result = await axios.get('http://localhost:4000/getevent');

            if (result.status === 400 || result.status === 404 || result.status === 409 || result.status === 500) {
                setLoader(false)
                return console.log("no events available");
            }

            setLoader(false)
            seteventData(result.data.response.events);


        } catch (error) {

            setLoader(false)
            console.log("Something went wrong while finding event");
            return console.log("event fetched failed");

        }
    }

    const columns = [
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">#</p>,
            cell: (row, index) => index + 1,
        },

        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Customer Name</p>,
            selector: (row) => row.name,
        },
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Mobile Number</p>,
            selector: (row) => row.phone,
        },
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Email Address</p>,
            selector: (row) => row.email,
        },
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Transaction Id</p>,
            selector: (row) => row.email,
        },
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Payment</p>,
            selector: (row) => row.email,
        },
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Payment Type</p>,
            selector: (row) => row.email,
        },


    ];

    const eventcolumns = [
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">#</p>,
            cell: (row, index) => index + 1,
        },

        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Event Name</p>,
            selector: (row) => row.name,
        },
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Price</p>,
            selector: (row) => row.price,
        },
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Date</p>,
            selector: (row) => row.date,
        },
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Location</p>,
            selector: (row) => row.location,
        },
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Revenue</p>,
            selector: (row) => row.revenue,
        },
        {
            name: <p className="font-[700] text-[#060606] text-[20px] font-poppins">Action</p>,
            cell: (row) => (
                <div className="flex gap-5">

                    <div className="delete cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path d="M9.33203 28.5C8.5987 28.5 7.97114 28.2391 7.44936 27.7173C6.9267 27.1947 6.66536 26.5667 6.66536 25.8333V8.5C6.28759 8.5 5.9707 8.37244 5.7147 8.11733C5.45959 7.86133 5.33203 7.54444 5.33203 7.16667C5.33203 6.78889 5.45959 6.472 5.7147 6.216C5.9707 5.96089 6.28759 5.83333 6.66536 5.83333H11.9987C11.9987 5.45556 12.1267 5.13867 12.3827 4.88267C12.6378 4.62756 12.9543 4.5 13.332 4.5H18.6654C19.0431 4.5 19.36 4.62756 19.616 4.88267C19.8711 5.13867 19.9987 5.45556 19.9987 5.83333H25.332C25.7098 5.83333 26.0263 5.96089 26.2814 6.216C26.5374 6.472 26.6654 6.78889 26.6654 7.16667C26.6654 7.54444 26.5374 7.86133 26.2814 8.11733C26.0263 8.37244 25.7098 8.5 25.332 8.5V25.8333C25.332 26.5667 25.0711 27.1947 24.5494 27.7173C24.0267 28.2391 23.3987 28.5 22.6654 28.5H9.33203ZM11.9987 21.8333C11.9987 22.2111 12.1267 22.5276 12.3827 22.7827C12.6378 23.0387 12.9543 23.1667 13.332 23.1667C13.7098 23.1667 14.0267 23.0387 14.2827 22.7827C14.5378 22.5276 14.6654 22.2111 14.6654 21.8333V12.5C14.6654 12.1222 14.5378 11.8053 14.2827 11.5493C14.0267 11.2942 13.7098 11.1667 13.332 11.1667C12.9543 11.1667 12.6378 11.2942 12.3827 11.5493C12.1267 11.8053 11.9987 12.1222 11.9987 12.5V21.8333ZM17.332 21.8333C17.332 22.2111 17.46 22.5276 17.716 22.7827C17.9711 23.0387 18.2876 23.1667 18.6654 23.1667C19.0431 23.1667 19.36 23.0387 19.616 22.7827C19.8711 22.5276 19.9987 22.2111 19.9987 21.8333V12.5C19.9987 12.1222 19.8711 11.8053 19.616 11.5493C19.36 11.2942 19.0431 11.1667 18.6654 11.1667C18.2876 11.1667 17.9711 11.2942 17.716 11.5493C17.46 11.8053 17.332 12.1222 17.332 12.5V21.8333Z" fill="#B53434" />
                        </svg>
                    </div>

                    <div className="edit cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path d="M25.7333 12.4003L20.0667 6.80033L21.9333 4.93366C22.4444 4.42255 23.0724 4.16699 23.8173 4.16699C24.5622 4.16699 25.1898 4.42255 25.7 4.93366L27.5667 6.80033C28.0778 7.31144 28.3444 7.92833 28.3667 8.65099C28.3889 9.37366 28.1444 9.9901 27.6333 10.5003L25.7333 12.4003ZM23.8 14.367L9.66667 28.5003H4V22.8337L18.1333 8.70033L23.8 14.367Z" fill="#060606" />
                        </svg>
                    </div>

                </div>
            )
        },


    ];


    const [dropdownopen, setdropdownopen] = useState(false);
    const [title, setTitle] = useState('Transaction')


    return (
        <div className='reports-container bg-[#F6F6F6] h-screen pt-6 '>


            {
                loader ? <div className='w-full h-[400px] flex justify-center items-center'> <ThreeDots
                    height="80"
                    width="130"
                    radius="9"
                    color="#0074be"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /> </div> :

                    <div className='px-4 relative' >

                        <div className="table-name flex justify-between items-center py-3 bg-[#FFF] px-4 ">

                            <div className=''>

                                <div className='flex gap-5 justify-center items-center'>

                                    <div className='flex justify-center items-center gap-1 cursor-pointer' onClick={() => setdropdownopen(!dropdownopen)}>

                                        <p className='text-[20px] md:text-[32px] font-[600] font-poppins tracking-[-0.32px]'>{title}</p>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M3.05 8.72577L11.45 17.1508C11.55 17.2508 11.6583 17.3218 11.775 17.3638C11.8917 17.4058 12.0167 17.4264 12.15 17.4258C12.2833 17.4258 12.4083 17.4051 12.525 17.3638C12.6417 17.3224 12.75 17.2514 12.85 17.1508L21.275 8.72577C21.5083 8.49243 21.625 8.20077 21.625 7.85077C21.625 7.50077 21.5 7.20077 21.25 6.95077C21 6.70077 20.7083 6.57577 20.375 6.57577C20.0417 6.57577 19.75 6.70077 19.5 6.95077L12.15 14.3008L4.8 6.95077C4.56667 6.71743 4.279 6.60077 3.937 6.60077C3.595 6.60077 3.29933 6.72577 3.05 6.97577C2.8 7.22577 2.675 7.51743 2.675 7.85077C2.675 8.1841 2.8 8.47577 3.05 8.72577Z" fill="#060606" />
                                        </svg>


                                    </div>

                                    <p className='text-[20px] md:text-[32px] font-[600] font-poppins tracking-[-0.32px]'>Reports </p>

                                </div>


                                {/* dropdown */}
                                {
                                    dropdownopen ? <div className='bg-[#F6F6F6] absolute z-50 w-[200px] py-2'>
                                        <p className='py-2 text-[16px] cursor-pointer text-center font-poppins text-[#060606] font-[600] hover:bg-gray-200 px-2' onClick={() => { setTitle('Event'); setdropdownopen(false) }}>Event</p>
                                        <hr className='border-[1px] border-b-[#e0e0e0]' />
                                        <p className='py-2 text-[16px] cursor-pointer text-center font-poppins text-[#060606] font-[600] hover:bg-gray-200 px-2' onClick={() => { setTitle('Transaction'); setdropdownopen(false) }}>Transaction</p>
                                    </div> : ""
                                }

                            </div>

                            {/* download button */}
                            <div className='py-[8px] px-[14px] md:px-[48px] md:py-[16px] bg-[#060606] text-[#FFF] cursor-pointer'>
                                <p className='text-[14px] md:text-[16px] font-[700] font-poppins tracking-[1.76px] hidden md:block'>Download Report</p>
                            </div>


                        </div>


                        <hr className='border-[1px] border-b-[#e0e0e0] mx-4' />

                        {
                            title === "Transaction" ? <DataTable
                                columns={columns}
                                data={eventdata}
                            /> : <DataTable
                                columns={eventcolumns}
                                data={eventdata}
                            />
                        }


                    </div>
            }



        </div>
    )
}

export default Reports