import React, { useState ,useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate,useParams} from "react-router-dom";
import { Select, Option } from "@material-tailwind/react";
import { useFormik } from "formik";
import AddEventSchemas from "../validationSchema/AddEventSchemas";
import { ADD_EVENT, EditEvent, FETCH_SINGLE_TICKET } from "../api/apiConstants";
import { apiClient, postData } from "../api/apiClient";
import { useQuery } from "react-query";
import moment from "moment";



const EditTicket = () => {


 const navigate = useNavigate()

  const { ticketId } = useParams();

  const fetchData = async () => {


   
    const response = await apiClient.get(FETCH_SINGLE_TICKET + '/' + ticketId);
    const data = response.data;
    if (data.code === 200) {
    
      return data.response;
    } else {
  throw new Error("Error fetching data");
    }
  };

  const { data: eventData, isLoading, isError , status } = useQuery(
    ["singleEvent", ticketId],
    () => fetchData(),
    {
      enabled: !_.isEmpty(ticketId), // Only enable the query if slugids is not empty
      onError: () => {
       
        // Handle error, for example, redirect to another page
        navigate("/");
      },
    }
  );



  return (
    <>
      {isLoading ? (
        <div className='w-full h-full   bg-[#F6F6F6]'>
          <div className='w-full h-[400px] flex justify-center items-center'>
            {" "}
            <ThreeDots
              height='80'
              width='130'
              radius='9'
              color='#0074be'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClassName=''
              visible={true}
            />{" "}
          </div>
        </div>
      ) : (
        <div className='bg-[#F6F6F6)] grid grid-cols-1 xl:grid-cols-2 gap-[2.5rem]  px-[1rem] py-[2rem] lg:px-[2.5rem] lg:py-[4rem]'>
          <div
            className='bg-[#F6F6F6] p-[1.2rem] lg:p-[1.55rem] flex flex-col gap-[1.5rem] lg:gap-[2.5rem]'
            style={{
              boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)",
            }}>
            <h1 className='font-poppins text-[#060606] text-[1.5rem] lg:text-[2rem] leading-[1rem] font-semibold'>
              Ticket
            </h1>
            <div className='bg-[#FFF] p-[1rem] lg:p-[1.5rem] flex flex-col gap-[1.2rem] lg:gap-[2.5rem]'>
              <div className='grid grid-cols-2'>
                <h1 className='font-poppins text-[1rem] lg:text-[1.2rem] text-[#808080] font-semibold'>
                  Name:
                </h1>
                <p className='font-poppins text-[1rem] lg:text-[1.2rem] text-[#060606] font-semibold'>
                  {eventData?.name}
                </p>
              </div>

              <div className='grid grid-cols-2'>
                <h1 className='font-poppins text-[1rem] lg:text-[1.2rem] text-[#808080] font-semibold'>
                  Query Type:
                </h1>
                <p className='font-poppins text-[1rem] lg:text-[1.2rem] text-[#060606] font-semibold'>
                  {eventData?.type}
                </p>
              </div>

              <div className='grid grid-cols-2'>
                <h1 className='font-poppins text-[1rem] lg:text-[1.2rem] text-[#808080] font-semibold'>
                  Date:
                </h1>
                <p className='font-poppins text-[1rem] lg:text-[1.2rem] text-[#060606] font-semibold'>
                  {moment(eventData?.created_At).format("Do, MMM YYYY")}
                </p>
              </div>
              <div className='grid grid-cols-2'>
                <h1 className='font-poppins text-[1rem] lg:text-[1.2rem] text-[#808080] font-semibold'>
                  Message:
                </h1>
                <p className='font-poppins text-[1rem] lg:text-[1.2rem] text-[#060606] font-semibold'>
                  {eventData?.messages}
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)",
            }}
            className='bg-[#F6F6F6] p-[1.2rem] flex flex-col gap-[1.5rem] lg:gap-[2.5rem]'>
            <div className='flex flex-row justify-between items-center'>
              <h1 className='font-poppins text-[#060606] text-[1.5rem] lg:text-[2rem] leading-[1rem] font-semibold'>
                Reply
              </h1>

              <button className='px-[1rem] py-[0.5rem] xl:h-[3.5rem] xl:w-[10rem]  bg-[#060606] text-[1rem] text-[#F6F6F6] font-poppins font-semibold'>
                Send
              </button>
            </div>

            <div className='w-full'>
              <textarea
                placeholder='Message'
                style={{ border: "1px solid  #808080", borderRadius: "1px" }}
                className='bg-transparent w-full outline-none p-[1rem] font-poppins text-[1rem] font-[500] placeholder:text-[#808080]'
                rows={5}
              />
            </div>

            <div style={{ border: "1px solid  #808080", borderRadius: "1px" }}>
              <select
                style={{ borderRight: "20px solid transparent" }}
                className='border-none w-full p-[1rem] outline-none '>
                <option selected value='inProgress'>
                  In Progress
                </option>
                <option value='completed'>Completed</option>
              </select>
            </div>
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default EditTicket;
