

export const ADD_EVENT = "/apiv2/addevents"
export const FETCH_EVENT = "/apiv2/fetch-events"
export const getOneEvent = "/apiv2/fetch-single"
export const EditEvent = "/apiv2/edit-event/"
export const FETCH_USER = "/api/fetch-users"
export const FETCH_TRANSACTIONS = "/api/fetch-transactions"
export const FETCH_EVENT_report = "/api/fetch-event-report"
export const FETCH_TICKET = "/api/fetch-tickets"
export const FETCH_SINGLE_TICKET = "/api/get-ticket"
export const DELETE_EVENT = "/api/delete-event"
export const FETCH_PAINTINGS = "/api/fetch/paintings"
export const ADD_PAINTINGS = "/apiv2/addPaintings"
export const EDIT_PAINTINGS = "/api/paintings"
export const GET_PAINT_EDIT = "/api/paintings/get"