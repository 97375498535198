import React, { useContext, useState, useEffect } from "react";
import { auth } from "../Firebase";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPhoneNumber,
  signOut,
  RecaptchaVerifier,
  updateProfile,
  reauthenticateWithPhoneNumber
} from "firebase/auth";
//import APIUrls from "../BaseUrl";
import axios from "axios";
import _ from "lodash";

// import { useDispatch, useSelector } from 'react-redux';


const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(false);
  const [userRole, setUserRole] = useState();
  const [loading, setLoading] = useState(true);
  const [recaptchaVerifier, setrecaptchaVerifier] = useState({});
  const [confirmationResult, setConfirmationResult] = useState();
  const [refresh,setrefresh] = useState(0);
  const [ walletLimitData,setWalletlimitdata] = useState(0)

//   const dispatch = useDispatch();


  console.log(currentUser?.accessToken);

  function logout() {
    return signOut(auth);
  }

  const updateUsers = async () => {
    const user = auth.currentUser;
    await user.reload();
    setCurrentUser(user);
  };

  async function login(email,password) {

    return await signInWithEmailAndPassword(auth, email, password);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }


 

  function getAccessToken() {
    return currentUser?.getIdToken(true);
  }
  function getCurrentUser() {
    return currentUser.getUser();
  }

  // const getUserRoleFunc= async(user)=>{
  //   try {
  //     console.log(user?.accessToken)
  //     const result= await fetch(APIUrls.getUserRole,{
  //       method:"GET",
  //       headers:{
  //        Authorization:`Bearer ${user.accessToken}`,
  //       }
  //     });
  //     const resultJson = await result.json();
  //     if (!result.ok) {
  //       throw new Error("Failed to login");
  //     }

  //     setUserRole(resultJson?.response?.role)

  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // async function LoginLogger (){ 
  //   const token = await getAccessToken()
  //          // Get browser CodeName
  //   const browserCodeName = navigator.appCodeName;

  //   // Get browser Name
  //   const browserName = navigator.appName;

  //   // Get browser Version
  //   const browserVersion = navigator.appVersion;

  //   // Check if cookies are enabled
  //   const cookiesEnabled = navigator.cookieEnabled;

  //   // Get platform
  //   const platform = navigator.platform;

  //   // Get user-agent header
  //   const userAgent = navigator.userAgent;
  //   // Additional properties you can include
  //   const language = navigator.language;
  //   const online = navigator.onLine;
  //   const doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack || 'unspecified';
  //   const javaEnabled = navigator.javaEnabled();

  //   // Create the client information object
  //   const info = {
  //     browserCodeName,
  //     browserName,
  //     browserVersion,
  //     cookiesEnabled,
  //     platform,
  //     userAgent,
  //     language,
  //     online,
  //     doNotTrack,
  //     javaEnabled,
  //   };

  //    await axios.post(APIUrls.LoginLogger, {device_info: info},
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     }
  //     );
  // }


//    const fetch_wallet_limit = async (tokens) => {
//       setLoading(true)
//         try {

//           const token = tokens
//           const response = await fetch(APIUrls.get_wallet_limit,{
//             headers: {
//               Authorization: `Bearer ${token}`, // Include the token in the request header
//             },
//           }); // Replace with your API endpoint
//           const limitData = await response.json();

//           console.log("limit data" ,limitData)
//           setWalletlimitdata(limitData);

//           setLoading(false)
//         } catch (error) {
//           console.error('Error fetching user data:', error);

//           setLoading(false)
//         }
//       };


      function get_wallet_lim() { 
            
        
         return walletLimitData?.response 
      }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
 
     
      setCurrentUser(user);
      
      console.log("lalalalallallal",user);
      const temp_token = user?.accessToken

      setLoading(false);
     
    });
    console.log(unsubscribe);

    return () => unsubscribe();
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    getAccessToken,
    getCurrentUser,
    userRole,
    confirmationResult,
    setConfirmationResult,
    updateUsers,
    get_wallet_lim
   
  
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
