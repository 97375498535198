import React, { useState } from 'react'
import logo from '../assets/logo.png'
import flodesignlogo from "../assets/flodesignlogo.svg";
import cartlogo from "../assets/cartlogo.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Sidebar = () => {
  const { logout, currentUser } = useAuth();

  const location = useLocation();

  console.log(location);

  const [open, setOpen] = useState(false);

  const [reportsTabStatus, setReportTabStatus] = useState(false);

  return (
    <div className='hidden md:hidden lg:flex justify-between flex-col w-80 bg-[#F6F6F6] overflow-auto'>
      <div className='top'>
        <NavLink to='/'>
          <div className='img w-[146px] h-[64px]  my-7 mx-auto'>
            <img src={flodesignlogo} alt='' className='w-full h-full' />
          </div>
        </NavLink>

        <div className='nav-NavLinks mx-10 '>
          <NavLink
            to='/'
            className={({ isActive, isPending }) =>
              `  nav w-full cursor-pointer py-4 my-3 text-[#060606]  hover:bg-[#0074BE] hover:shadow-none hover:text-[#fff]  flex items-center gap-4 group  ${
                isActive
                  ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                  : "text-[#060606]"
              }   `
            }
            end>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'>
              <path
                d='M13 9V3H21V9H13ZM3 13V3H11V13H3ZM13 21V11H21V21H13ZM3 21V15H11V21H3Z'
                fill='currentColor'
              />
            </svg>
            <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
              DASHBOARD
            </p>
          </NavLink>

          <div
            className={`w-full cursor-pointer py-4 my-3 text-[#060606]  hover:bg-transparent hover:shadow-none hover:text-[#060606]  flex flex-row justify-between items-center gap-4 group ${
              open ? "bg-[#D9D9D9] pl-2" : ""
            }`}
            onClick={() => setOpen(!open)}>
            <div className='flex flex-row gap-4'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                className='text-[#060606]'
                height='24'
                viewBox='0 0 24 24'
                fill='none'>
                <path
                  d='M14.5 18C13.8 18 13.2083 17.7583 12.725 17.275C12.2417 16.7917 12 16.2 12 15.5C12 14.8 12.2417 14.2083 12.725 13.725C13.2083 13.2417 13.8 13 14.5 13C15.2 13 15.7917 13.2417 16.275 13.725C16.7583 14.2083 17 14.8 17 15.5C17 16.2 16.7583 16.7917 16.275 17.275C15.7917 17.7583 15.2 18 14.5 18ZM5 22C4.45 22 3.979 21.804 3.587 21.412C3.195 21.02 2.99934 20.5493 3 20V6C3 5.45 3.196 4.979 3.588 4.587C3.98 4.195 4.45067 3.99933 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.805 4.98 21.0007 5.45067 21 6V20C21 20.55 20.804 21.021 20.412 21.413C20.02 21.805 19.5493 22.0007 19 22H5ZM5 20H19V10H5V20Z'
                  fill='currentColor'
                />
              </svg>
              <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                Event Management
              </p>
            </div>
            {open ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'>
                <path
                  d='M3.42501 15.8504L11.825 7.42541C11.925 7.32541 12.0333 7.25441 12.15 7.21241C12.2667 7.17041 12.3917 7.14974 12.525 7.15041C12.6583 7.15041 12.7833 7.17107 12.9 7.21241C13.0167 7.25374 13.125 7.32474 13.225 7.42541L21.65 15.8504C21.8833 16.0837 22 16.3754 22 16.7254C22 17.0754 21.875 17.3754 21.625 17.6254C21.375 17.8754 21.0833 18.0004 20.75 18.0004C20.4167 18.0004 20.125 17.8754 19.875 17.6254L12.525 10.2754L5.17501 17.6254C4.94167 17.8587 4.65401 17.9754 4.31201 17.9754C3.97001 17.9754 3.67434 17.8504 3.42501 17.6004C3.17501 17.3504 3.05001 17.0587 3.05001 16.7254C3.05001 16.3921 3.17501 16.1004 3.42501 15.8504Z'
                  fill='#060606'
                />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'>
                <path
                  d='M3.42501 9.29998L11.825 17.725C11.925 17.825 12.0333 17.896 12.15 17.938C12.2667 17.98 12.3917 18.0007 12.525 18C12.6583 18 12.7833 17.9793 12.9 17.938C13.0167 17.8967 13.125 17.8257 13.225 17.725L21.65 9.29998C21.8833 9.06665 22 8.77498 22 8.42498C22 8.07498 21.875 7.77498 21.625 7.52498C21.375 7.27498 21.0833 7.14998 20.75 7.14998C20.4167 7.14998 20.125 7.27498 19.875 7.52498L12.525 14.875L5.17501 7.52498C4.94167 7.29165 4.65401 7.17498 4.31201 7.17498C3.97001 7.17498 3.67434 7.29998 3.42501 7.54998C3.17501 7.79998 3.05001 8.09165 3.05001 8.42498C3.05001 8.75832 3.17501 9.04998 3.42501 9.29998Z'
                  fill='#060606'
                />
              </svg>
            )}
          </div>

          {open ? (
            <div className='px-6'>
              <NavLink
                to='/addevent'
                className={({ isActive, isPending }) =>
                  `  nav w-full cursor-pointer py-4 my-3 text-[#060606]  hover:bg-[#0074BE] hover:shadow-none hover:text-[#fff]  flex items-center gap-4 group  ${
                    isActive
                      ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                      : "text-[#060606]"
                  }   `
                }
                end>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'>
                  <path
                    d='M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.4217 2.75 18.8923 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.655 21.1083 7.11733 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z'
                    fill='currentColor'
                  />
                </svg>
                <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                  Create events
                </p>
              </NavLink>

              <NavLink
                to='/event'
                className={({ isActive, isPending }) =>
                  `  nav w-full cursor-pointer py-4 my-3 text-[#060606]   hover:bg-[#0074BE] hover:shadow-none hover:text-[#fff]  flex items-center gap-4 group  ${
                    isActive
                      ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                      : "text-[#060606]"
                  }   `
                }
                end>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'>
                  <path
                    d='M14.5 18C13.8 18 13.2083 17.7583 12.725 17.275C12.2417 16.7917 12 16.2 12 15.5C12 14.8 12.2417 14.2083 12.725 13.725C13.2083 13.2417 13.8 13 14.5 13C15.2 13 15.7917 13.2417 16.275 13.725C16.7583 14.2083 17 14.8 17 15.5C17 16.2 16.7583 16.7917 16.275 17.275C15.7917 17.7583 15.2 18 14.5 18ZM5 22C4.45 22 3.979 21.804 3.587 21.412C3.195 21.02 2.99934 20.5493 3 20V6C3 5.45 3.196 4.979 3.588 4.587C3.98 4.195 4.45067 3.99933 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.805 4.98 21.0007 5.45067 21 6V20C21 20.55 20.804 21.021 20.412 21.413C20.02 21.805 19.5493 22.0007 19 22H5ZM5 20H19V10H5V20Z'
                    fill='currentColor'
                  />
                </svg>
                <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                  View All Events
                </p>
              </NavLink>
            </div>
          ) : (
            ""
          )}

          <NavLink
            to='/records'
            className={({ isActive, isPending }) =>
              `  nav w-full cursor-pointer py-4 my-3 text-[#060606]  hover:bg-[#0074BE] hover:shadow-none hover:text-[#fff]  flex items-center gap-4 group  ${
                isActive
                  ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                  : "text-[#060606]"
              }   `
            }
            end>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              className=''
              height='24'
              viewBox='0 0 24 24'
              fill='none'>
              <path
                d='M12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM9.5 13.5C10.4667 13.5 11.2917 13.1583 11.975 12.475C12.6583 11.7917 13 10.9667 13 10C13 9.03333 12.6583 8.20833 11.975 7.525C11.2917 6.84167 10.4667 6.5 9.5 6.5C8.53333 6.5 7.70833 6.84167 7.025 7.525C6.34167 8.20833 6 9.03333 6 10C6 10.9667 6.34167 11.7917 7.025 12.475C7.70833 13.1583 8.53333 13.5 9.5 13.5ZM16.5 14.5C17.2 14.5 17.7917 14.2583 18.275 13.775C18.7583 13.2917 19 12.7 19 12C19 11.3 18.7583 10.7083 18.275 10.225C17.7917 9.74167 17.2 9.5 16.5 9.5C15.8 9.5 15.2083 9.74167 14.725 10.225C14.2417 10.7083 14 11.3 14 12C14 12.7 14.2417 13.2917 14.725 13.775C15.2083 14.2583 15.8 14.5 16.5 14.5ZM12 20C13.4167 20 14.7083 19.6667 15.875 19C17.0417 18.3333 17.9833 17.45 18.7 16.35C18.35 16.25 17.9917 16.1667 17.625 16.1C17.2583 16.0333 16.8833 16 16.5 16C15.6167 16 14.55 16.2583 13.3 16.775C12.05 17.2917 11.05 18.3083 10.3 19.825C10.5833 19.8917 10.8667 19.9377 11.15 19.963C11.4333 19.9883 11.7167 20.0007 12 20ZM8.825 19.35C9.40833 18.15 10.071 17.2583 10.813 16.675C11.555 16.0917 12.1173 15.7 12.5 15.5C12.0167 15.35 11.529 15.229 11.037 15.137C10.545 15.045 10.0327 14.9993 9.5 15C8.75 15 8.00833 15.0917 7.275 15.275C6.54167 15.4583 5.83333 15.7167 5.15 16.05C5.58333 16.7667 6.11267 17.4127 6.738 17.988C7.36333 18.5633 8.059 19.0173 8.825 19.35Z'
                fill='currentColor'
              />
            </svg>
            <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
              User Records
            </p>
          </NavLink>

          {/* <NavLink
            to='/sendupdates'
            className={({ isActive, isPending }) =>
              `  nav w-full cursor-pointer py-4 my-3 text-[#060606]  hover:bg-[#0074BE] hover:shadow-none hover:text-[#fff]  flex items-center gap-4 group  ${
                isActive
                  ? "bg-[#0074BE] text-[#fff] px-[12px] "
                  : "text-[#060606]"
              }   `
            }
            end>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'>
              <path d='M3 20V14L11 12L3 10V4L22 12L3 20Z' fill='currentColor' />
            </svg>
            <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
              Send Updates
            </p>
          </NavLink> */}

          <div
            className={`w-full cursor-pointer py-4  text-[#060606]   hover:bg-transparent hover:shadow-none gap-4 hover:text-[#060606]  flex flex-row  justify-between  items-center    group  ${
              reportsTabStatus ? "bg-[#D9D9D9] pl-2" : ""
            }`}
            onClick={() => setReportTabStatus(!reportsTabStatus)}>
            <div className='flex flex-row gap-4'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'>
                <path
                  d='M16 11.78L20.24 4.45L21.97 5.45L16.74 14.5L10.23 10.75L5.46 19H22V21H2V3H4V17.54L9.5 8L16 11.78Z'
                  fill='#060606'
                />
              </svg>
              <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                Reports
              </p>
            </div>

            {reportsTabStatus ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'>
                <path
                  d='M3.42501 15.8504L11.825 7.42541C11.925 7.32541 12.0333 7.25441 12.15 7.21241C12.2667 7.17041 12.3917 7.14974 12.525 7.15041C12.6583 7.15041 12.7833 7.17107 12.9 7.21241C13.0167 7.25374 13.125 7.32474 13.225 7.42541L21.65 15.8504C21.8833 16.0837 22 16.3754 22 16.7254C22 17.0754 21.875 17.3754 21.625 17.6254C21.375 17.8754 21.0833 18.0004 20.75 18.0004C20.4167 18.0004 20.125 17.8754 19.875 17.6254L12.525 10.2754L5.17501 17.6254C4.94167 17.8587 4.65401 17.9754 4.31201 17.9754C3.97001 17.9754 3.67434 17.8504 3.42501 17.6004C3.17501 17.3504 3.05001 17.0587 3.05001 16.7254C3.05001 16.3921 3.17501 16.1004 3.42501 15.8504Z'
                  fill='#060606'
                />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'>
                <path
                  d='M3.42501 9.29998L11.825 17.725C11.925 17.825 12.0333 17.896 12.15 17.938C12.2667 17.98 12.3917 18.0007 12.525 18C12.6583 18 12.7833 17.9793 12.9 17.938C13.0167 17.8967 13.125 17.8257 13.225 17.725L21.65 9.29998C21.8833 9.06665 22 8.77498 22 8.42498C22 8.07498 21.875 7.77498 21.625 7.52498C21.375 7.27498 21.0833 7.14998 20.75 7.14998C20.4167 7.14998 20.125 7.27498 19.875 7.52498L12.525 14.875L5.17501 7.52498C4.94167 7.29165 4.65401 7.17498 4.31201 7.17498C3.97001 7.17498 3.67434 7.29998 3.42501 7.54998C3.17501 7.79998 3.05001 8.09165 3.05001 8.42498C3.05001 8.75832 3.17501 9.04998 3.42501 9.29998Z'
                  fill='#060606'
                />
              </svg>
            )}
          </div>

          {reportsTabStatus ? (
            <div className='px-6'>
              <NavLink
                to={"/transaction-reports"}
                className={({ isActive, isPending }) =>
                  `  nav w-full cursor-pointer py-4 my-3 text-[#060606]  hover:bg-[#0074BE] hover:shadow-none hover:text-[#fff]  flex items-center gap-4 group  ${
                    isActive
                      ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                      : "text-[#060606]"
                  }   `
                }
                end>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'>
                  <path
                    d='M3.70084 11.8172C3.74487 9.68111 4.61423 7.64505 6.12662 6.13594C6.88834 5.37422 7.77428 4.77422 8.761 4.35703C9.78287 3.92578 10.868 3.70547 11.986 3.70547C13.1063 3.70547 14.1915 3.92344 15.211 4.35703C16.1977 4.77422 17.0837 5.37422 17.8454 6.13594C18.0797 6.37031 18.2977 6.61641 18.4993 6.87188L17.3274 7.78828C17.2995 7.80988 17.2782 7.83891 17.2661 7.87205C17.2539 7.90519 17.2514 7.94108 17.2587 7.97561C17.2661 8.01013 17.283 8.04188 17.3076 8.0672C17.3322 8.09252 17.3634 8.11039 17.3977 8.11875L21.0727 9.01641C21.1899 9.04453 21.3047 8.95547 21.3047 8.83594L21.3235 5.05078C21.3235 4.89375 21.143 4.80469 21.0212 4.90312L19.9008 5.77969C18.0633 3.42891 15.2016 1.92188 11.9883 1.92188C6.49225 1.92188 2.02271 6.33047 1.92193 11.8078C1.92131 11.8328 1.9257 11.8577 1.93484 11.881C1.94398 11.9043 1.95769 11.9255 1.97517 11.9434C1.99264 11.9613 2.01352 11.9756 2.03658 11.9853C2.05964 11.995 2.08441 12 2.10943 12H3.51568C3.61647 12 3.6985 11.918 3.70084 11.8172ZM21.8907 12H20.4844C20.3837 12 20.2993 12.082 20.2969 12.1828C20.2529 14.3189 19.3835 16.355 17.8712 17.8641C17.1164 18.6219 16.2218 19.2261 15.2368 19.643C14.2149 20.0742 13.1297 20.2945 12.0118 20.2945C10.8938 20.2945 9.80631 20.0766 8.78678 19.643C7.8018 19.2261 6.90713 18.6219 6.1524 17.8641C5.91803 17.6297 5.70006 17.3836 5.4985 17.1281L6.66803 16.2117C6.69594 16.1901 6.7172 16.1611 6.72935 16.1279C6.74149 16.0948 6.74404 16.0589 6.7367 16.0244C6.72935 15.9899 6.71241 15.9581 6.68783 15.9328C6.66324 15.9075 6.63201 15.8896 6.59772 15.8812L2.92271 14.9836C2.80553 14.9555 2.69068 15.0445 2.69068 15.1641L2.67193 18.9539C2.67193 19.1109 2.8524 19.2 2.97428 19.1016L4.09459 18.225C5.93678 20.5711 8.7985 22.0781 12.0118 22.0781C17.5079 22.0781 21.9774 17.6695 22.0782 12.1922C22.0788 12.1672 22.0744 12.1423 22.0653 12.119C22.0561 12.0957 22.0424 12.0745 22.025 12.0566C22.0075 12.0387 21.9866 12.0244 21.9635 12.0147C21.9405 12.005 21.9157 12 21.8907 12Z'
                    fill='currentColor'
                  />
                  <path
                    d='M9.55556 7H15.1111L14.5556 8.11111H12.7444C13.0111 8.43333 13.2111 8.81111 13.3278 9.22222H15.1111L14.5556 10.3333H13.4444C13.3756 11.026 13.0747 11.675 12.5907 12.1752C12.1066 12.6754 11.4678 12.9974 10.7778 13.0889V13.1111H10.3889L13.7222 17H12.3333L9 13.1111V12H10.3889C11.3667 12 12.1778 11.2778 12.3111 10.3333H9L9.55556 9.22222H12.1444C11.8333 8.56667 11.1667 8.11111 10.3889 8.11111H9L9.55556 7Z'
                    fill='currentColor'
                  />
                </svg>
                <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                  Transaction
                </p>
              </NavLink>

              <NavLink
                to={"/event-reports"}
                className={({ isActive, isPending }) =>
                  `  nav w-full cursor-pointer py-4 my-3 text-[#060606]  hover:bg-[#0074BE] hover:shadow-none hover:text-[#fff]  flex items-center gap-4 group  ${
                    isActive
                      ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                      : "text-[#060606]"
                  }   `
                }
                end>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'>
                  <path
                    d='M14.5 18C13.8 18 13.2083 17.7583 12.725 17.275C12.2417 16.7917 12 16.2 12 15.5C12 14.8 12.2417 14.2083 12.725 13.725C13.2083 13.2417 13.8 13 14.5 13C15.2 13 15.7917 13.2417 16.275 13.725C16.7583 14.2083 17 14.8 17 15.5C17 16.2 16.7583 16.7917 16.275 17.275C15.7917 17.7583 15.2 18 14.5 18ZM5 22C4.45 22 3.979 21.804 3.587 21.412C3.195 21.02 2.99934 20.5493 3 20V6C3 5.45 3.196 4.979 3.588 4.587C3.98 4.195 4.45067 3.99933 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.805 4.98 21.0007 5.45067 21 6V20C21 20.55 20.804 21.021 20.412 21.413C20.02 21.805 19.5493 22.0007 19 22H5ZM5 20H19V10H5V20Z'
                    fill='currentColor'
                  />
                </svg>
                <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                  Events
                </p>
              </NavLink>
            </div>
          ) : (
            ""
          )}

          <NavLink
            to='/shop'
            className={({ isActive, isPending }) =>
              `  nav w-full cursor-pointer py-4 my-3 text-[#060606]  hover:bg-[#0074BE] hover:shadow-none   flex items-center gap-4 group  ${
                isActive
                  ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                  : "text-[#060606]"
              }   `
            }
            end>
            {console.log(location.pathname === "/shop")}
            {/* <img
              src={cartlogo}
              className={`  ${
                location.pathname === "/shop" && "text-[#fff]"
              }`}
            /> */}

            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'>
              <path
                d='M17 18C15.89 18 15 18.89 15 20C15 20.5304 15.2107 21.0391 15.5858 21.4142C15.9609 21.7893 16.4696 22 17 22C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20C19 19.4696 18.7893 18.9609 18.4142 18.5858C18.0391 18.2107 17.5304 18 17 18ZM1 2V4H3L6.6 11.59L5.24 14.04C5.09 14.32 5 14.65 5 15C5 15.5304 5.21071 16.0391 5.58579 16.4142C5.96086 16.7893 6.46957 17 7 17H19V15H7.42C7.3537 15 7.29011 14.9737 7.24322 14.9268C7.19634 14.8799 7.17 14.8163 7.17 14.75C7.17 14.7 7.18 14.66 7.2 14.63L8.1 13H15.55C16.3 13 16.96 12.58 17.3 11.97L20.88 5.5C20.95 5.34 21 5.17 21 5C21 4.73478 20.8946 4.48043 20.7071 4.29289C20.5196 4.10536 20.2652 4 20 4H5.21L4.27 2M7 18C5.89 18 5 18.89 5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22C7.53043 22 8.03914 21.7893 8.41421 21.4142C8.78929 21.0391 9 20.5304 9 20C9 19.4696 8.78929 18.9609 8.41421 18.5858C8.03914 18.2107 7.53043 18 7 18Z'
                fill='currentColor'
              />
            </svg>
            <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
              Shop
            </p>
          </NavLink>
        </div>
      </div>

      <div className='bottom mx-10'>
        <NavLink
          to='/ticketmanagement'
          className={({ isActive, isPending }) =>
            `  nav w-full cursor-pointer py-4 my-3 text-[#060606] hover:bg-[#0074BE] hover:shadow-none hover:text-[#fff]  flex items-center gap-4 group  ${
              isActive
                ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                : "text-[#060606]"
            }   `
          }
          end>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'>
            <path
              d='M12 22L11.75 19H11.5C9.13333 19 7.125 18.175 5.475 16.525C3.825 14.875 3 12.8667 3 10.5C3 8.13333 3.825 6.125 5.475 4.475C7.125 2.825 9.13333 2 11.5 2C12.6833 2 13.7877 2.22067 14.813 2.662C15.8383 3.10333 16.7383 3.71167 17.513 4.487C18.2877 5.26233 18.8957 6.16233 19.337 7.187C19.7783 8.21167 19.9993 9.316 20 10.5C20 11.75 19.7957 12.95 19.387 14.1C18.9783 15.25 18.42 16.3167 17.712 17.3C17.004 18.2833 16.1623 19.175 15.187 19.975C14.2117 20.775 13.1493 21.45 12 22ZM11.475 15.975C11.7583 15.975 12 15.875 12.2 15.675C12.4 15.475 12.5 15.2333 12.5 14.95C12.5 14.6667 12.4 14.425 12.2 14.225C12 14.025 11.7583 13.925 11.475 13.925C11.1917 13.925 10.95 14.025 10.75 14.225C10.55 14.425 10.45 14.6667 10.45 14.95C10.45 15.2333 10.55 15.475 10.75 15.675C10.95 15.875 11.1917 15.975 11.475 15.975ZM10.75 12.8H12.25C12.25 12.3 12.3 11.95 12.4 11.75C12.5 11.55 12.8167 11.1833 13.35 10.65C13.65 10.35 13.9 10.025 14.1 9.675C14.3 9.325 14.4 8.95 14.4 8.55C14.4 7.7 14.1123 7.06233 13.537 6.637C12.9617 6.21167 12.2827 5.99933 11.5 6C10.7667 6 10.15 6.20433 9.65 6.613C9.15 7.02167 8.8 7.51733 8.6 8.1L10 8.65C10.0833 8.36667 10.2417 8.08733 10.475 7.812C10.7083 7.53667 11.05 7.39933 11.5 7.4C11.95 7.4 12.2873 7.525 12.512 7.775C12.7367 8.025 12.8493 8.3 12.85 8.6C12.85 8.88333 12.7667 9.13767 12.6 9.363C12.4333 9.58833 12.2333 9.81733 12 10.05C11.4167 10.55 11.0623 10.946 10.937 11.238C10.8117 11.53 10.7493 12.0507 10.75 12.8Z'
              fill='currentColor'
            />
          </svg>
          <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
            Ticket Management
          </p>
        </NavLink>

        <div
          onClick={() => logout()}
          className='nav w-full cursor-pointer py-4 text-[#060606] hover:bg-[#0074BE] hover:shadow-none hover:text-[#fff] flex items-center gap-4 group'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            className='text-[#060606] group-hover:text-white'
            height='24'
            viewBox='0 0 24 24'
            fill='none'>
            <path
              d='M19 21H10C8.89543 21 8 20.1046 8 19V15H10V19H19V5H10V9H8V5C8 3.89543 8.89543 3 10 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM12 16V13H3V11H12V8L17 12L12 16Z'
              fill='currentColor'
            />
          </svg>
          <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
            Log Out
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar