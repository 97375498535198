import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, NavLink, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { ThreeDots } from "react-loader-spinner";
import CustomPagination from "../CustomPagination";
import { apiClient } from "../../api/apiClient";
import { FETCH_TRANSACTIONS } from "../../api/apiConstants";

const TransactionReports = () => {
  const navigate = useNavigate();

  const columns = [
    {
      name: (
        <p className="font-[700] text-[#060606] text-[20px] font-poppins">#</p>
      ),
      cell: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: (
        <p className="font-[700] text-[#060606] text-[20px] font-poppins">
          Customer Name
        </p>
      ),
      id: "customerName",
      // width: "200px",
      grow: 2,
      selector: (row) =>
        row?.customerData.FirstName + " " + row?.customerData.LastName,
    },
    {
      name: (
        <p className="font-[700] text-[#060606] text-[20px] font-poppins">
          Mobile Number
        </p>
      ),
      id: "number",
      // width: "210px",
      grow: 1.3,
      selector: (row) => row?.customerData.MobileNo,
    },
    {
      name: (
        <p className="font-[700] text-[#060606] text-[20px] font-poppins">
          Email Address
        </p>
      ),
      id: "email",
      // width: "200px",
      grow: 2,
      selector: (row) => row.customerData.Email,
    },
    {
      name: "Transaction Id",
      id: "transactionId",
      // width: "190px",
      grow: 1.7,
      selector: (row) => row.OrderId,
    },
    {
      name: "Paid",
      id: "payment",
      selector: (row) => "₹" + row.OrderAmount,
      grow: 1,
    },
    {
      name: "Payment Type",
      id: "paymentType",
      selector: (row) => row.PaymentMethod,
      grow: 1.2,
    },
    {
      name: "Status",
      id: "paymentType",
      selector: (row) => (
        <div className="my-2">
          {row?.OrderPaymentStatusText !== "Success" ? (
            <button className="p-[12px] bg-[#FBD7D7] text-[14px] font-[600] text-[#B53434] ">
              Pending
            </button>
          ) : (
            <button className="p-[12px] bg-[#E2FBD7] text-[14px] font-[600] text-[#34B53A] px-[16px]">
              {row?.OrderPaymentStatusText.toUpperCase()}
            </button>
          )}
        </div>
      ),
    },
    // {
    //   name: (
    //     <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
    //       Actions
    //     </p>
    //   ),
    //   width: "200px",
    //   cell: (row) => (
    //     <div className='flex gap-5'>
    //       <div className='delete cursor-pointer'>
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           width='32'
    //           height='33'
    //           viewBox='0 0 32 33'
    //           fill='none'>
    //           <path
    //             d='M9.33203 28.5C8.5987 28.5 7.97114 28.2391 7.44936 27.7173C6.9267 27.1947 6.66536 26.5667 6.66536 25.8333V8.5C6.28759 8.5 5.9707 8.37244 5.7147 8.11733C5.45959 7.86133 5.33203 7.54444 5.33203 7.16667C5.33203 6.78889 5.45959 6.472 5.7147 6.216C5.9707 5.96089 6.28759 5.83333 6.66536 5.83333H11.9987C11.9987 5.45556 12.1267 5.13867 12.3827 4.88267C12.6378 4.62756 12.9543 4.5 13.332 4.5H18.6654C19.0431 4.5 19.36 4.62756 19.616 4.88267C19.8711 5.13867 19.9987 5.45556 19.9987 5.83333H25.332C25.7098 5.83333 26.0263 5.96089 26.2814 6.216C26.5374 6.472 26.6654 6.78889 26.6654 7.16667C26.6654 7.54444 26.5374 7.86133 26.2814 8.11733C26.0263 8.37244 25.7098 8.5 25.332 8.5V25.8333C25.332 26.5667 25.0711 27.1947 24.5494 27.7173C24.0267 28.2391 23.3987 28.5 22.6654 28.5H9.33203ZM11.9987 21.8333C11.9987 22.2111 12.1267 22.5276 12.3827 22.7827C12.6378 23.0387 12.9543 23.1667 13.332 23.1667C13.7098 23.1667 14.0267 23.0387 14.2827 22.7827C14.5378 22.5276 14.6654 22.2111 14.6654 21.8333V12.5C14.6654 12.1222 14.5378 11.8053 14.2827 11.5493C14.0267 11.2942 13.7098 11.1667 13.332 11.1667C12.9543 11.1667 12.6378 11.2942 12.3827 11.5493C12.1267 11.8053 11.9987 12.1222 11.9987 12.5V21.8333ZM17.332 21.8333C17.332 22.2111 17.46 22.5276 17.716 22.7827C17.9711 23.0387 18.2876 23.1667 18.6654 23.1667C19.0431 23.1667 19.36 23.0387 19.616 22.7827C19.8711 22.5276 19.9987 22.2111 19.9987 21.8333V12.5C19.9987 12.1222 19.8711 11.8053 19.616 11.5493C19.36 11.2942 19.0431 11.1667 18.6654 11.1667C18.2876 11.1667 17.9711 11.2942 17.716 11.5493C17.46 11.8053 17.332 12.1222 17.332 12.5V21.8333Z'
    //             fill='#B53434'
    //           />
    //         </svg>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [page]);

  useEffect(() => {
    getallevents();
  }, []);

  const getallevents = async () => {
    setLoader(true);
    try {
      const result = await apiClient.get(FETCH_TRANSACTIONS);

      if (
        result.status === 400 ||
        result.status === 404 ||
        result.status === 409 ||
        result.status === 500
      ) {
        setLoader(false);
        return console.log("no events available");
      }

      setLoader(false);
      setData(result?.data?.response?.TransacsArray?.reverse());
    } catch (error) {
      console.log(error);
      setLoader(false);
      console.log("Something went wrong while finding event");
      return console.log("event fetched failed");
    }
  };
  const userRecordsData = [
    {
      customerName: "Sudheer",
      number: "910034441",
      email: "xyz@gmail.com",
      payment: "2100",
      transactionId: "12324",
      paymentType: "Credit Card",
    },
    {
      customerName: "Sudheer",
      number: "910034441",
      email: "xyz@gmail.com",
      payment: "2100",
      transactionId: "12324",
      paymentType: "Credit Card",
    },
    {
      customerName: "Sudheer",
      number: "910034441",
      email: "xyz@gmail.com",
      payment: "2100",
      transactionId: "12324",
      paymentType: "Credit Card",
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: "bold",
        border: "none",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",

        fontSize: "20px",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        fontWeight: "800",
        height: "75px",
      },
    },
    header: {
      style: {},
    },
    rows: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",
        backgroundColor: "transparent",

        marginBottom: "-1px",

        fontSize: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        fontWeight: "600",
        overflow: "hidden",
        height: "75px",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "10px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1550px",
        borderCollapse: "collapse",
      },
    },
    headCells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    cells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
  };

  return (
    <>
      {loader ? (
        <div className="w-full h-full   bg-[#F6F6F6]">
          <div className="w-full h-[400px] flex justify-center items-center">
            {" "}
            <ThreeDots
              height="80"
              width="130"
              radius="9"
              color="#0074be"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />{" "}
          </div>
        </div>
      ) : (
        <div className="w-full py-10  bg-[#F6F6F6]">
          <div
            className="mx-4 md:mx-6 pb-4 mb-4 bg-[#fff]"
            style={{
              boxShadow:
                "0px 0px 15px 0px #ddd, 0px 4px 16px rgba(0, 0, 0, 0.25) ",
            }}
          >
            <div className="bg-[#fff] px-4 md:px-8 flex flex-row justify-between items-center h-[72px] md:h-[90px] ">
              <h1 className="font-poppins text-[20px] md:text-[30px] text-[#060606] font-[600] leading-[24px] ">
                Transaction Report
              </h1>

              <div className="flex md:gap-10 gap-2">
                <button
                  onClick={() => navigate("/addevent")}
                  className="flex py-[8px] px-[14px] md:p-[16px] border-[1px] border-[#808080] gap-2 cursor-pointer justify-center items-center"
                >
                  <p className="text-[14px] md:text-[16px] font-[700] font-poppins tracking-[1.76px] hidden md:block">
                    Create Event
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.4217 2.75 18.8923 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.655 21.1083 7.11733 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z"
                      fill="#060606"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="">
              <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                pagination
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionReports;
