import { useState } from "react";

import { Icon } from "@iconify/react";

const ChangePassword = () => {
  const [showPassword, setPasswordStatus] = useState(false);
  return (
    <div className='bg-[#F6F6F6]  px-[1rem] py-[2rem] lg:px-[2.5rem] lg:py-[4rem] '>
      <div
        style={{ boxShadow: " 0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
        className='bg-[#F6F6F6] p-[1rem] lg:p-[1.7rem] flex flex-col gap-[1.5rem] lg:gap-[2.5rem]  lg:h-[750px]'>
        <div className='flex flex-row justify-between items-center font-poppins'>
          <h1 className='text-[#060606] text-[1.25rem] lg:text-[2rem] leading-[1.5rem] font-[600]'>
            Change Password
          </h1>

          <button className='bg-[#060606] h-[2rem] w-[4.8rem] text-[0.8rem] lg:h-[4rem] lg:w-[12rem] text-[#F6F6F6] leading-[1rem] font-bold'>
            Save
          </button>
        </div>

        <div className='flex flex-col gap-[1.2rem] lg:gap-[2.5rem]'>
          <div className='flex flex-col gap-[1rem] w-full'>
            <div className='relative'>
              <input
                type='password'
                value={"..........."}
                id='currentPassword'
                className='h-[4.5rem]  lg:h-[5rem]   bg-transparent w-full  font-poppins text-[#060606] text-[1.2rem] font-[500]  peer pt-4 pb-2 px-[1rem] border border-solid  border-[#808080] placeholder-transparent outline-none  block '
              />
              <label
                htmlFor='name'
                className='absolute left-2 -top-2.5 text-[#060606] text-[1rem] font-[600] bg-white px-1 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:left-2 peer-placeholder-shown:text-base  peer-focus:-top-2.5 peer-focus:left-2 peer-focus:text-[1rem] '>
                Current Password
              </label>
            </div>
          </div>
          <div className='flex flex-col gap-[1rem] w-full border border-solid  border-[#808080]'>
            <div className='flex flex-row '>
              <div className='relative grow  px-[1rem]'>
                <input
                  type={showPassword ? "text" : "password"}
                  value={"*************"}
                  id='newPassword'
                  className='h-[4.5rem]  lg:h-[5rem]  bg-transparent w-full border-none  font-poppins text-[#060606] text-[1.2rem] font-[500]  peer pt-4 pb-2 px-2  placeholder-transparent outline-none  block '
                />
                <label
                  htmlFor='name'
                  className='absolute left-2 -top-2.5 text-[#060606] text-[1rem] font-[600] bg-white px-1 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:left-2 peer-placeholder-shown:text-base  peer-focus:-top-2.5 peer-focus:left-2 peer-focus:text-[1rem] '>
                  New Password
                </label>
              </div>
              <button
                className='flex flex-row justify-center items-center w-[50px] lg:w-[100px]'
                onClick={() => setPasswordStatus(!showPassword)}>
                {showPassword ? (
                  <Icon
                    icon='ic:baseline-visibility-off'
                    className='text-[1.5rem]'
                  />
                ) : (
                  <Icon
                    icon='material-symbols:visibility'
                    className='text-[1.5rem]'
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
