import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { apiClient } from "../../api/apiClient";
import { FETCH_PAINTINGS } from "../../api/apiConstants";
import { toast } from "react-toastify";

export default function ShopManagement() {
  const navigate = useNavigate();
  const [paintings, setPaintings] = useState([]);
  const [load, setLoad] = useState(false);

  const deleteProduct = async (productId) => {
    console.log("deleteProduct", productId);
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await apiClient.delete(`/api/paintings/delete/${productId}`);
        // Refresh the list or remove the item from the state
        setPaintings(
          paintings.filter((painting) => painting._id !== productId)
        );
        toast.success("Product deleted successfully");
      } catch (error) {
        toast.error("Error deleting product:", error);
      }
    }
  };

  const customStyles = {
    headRow: {
      style: {
        fontWeight: "bold",
        border: "none",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",

        fontSize: "20px",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        fontWeight: "800",
        height: "75px",
      },
    },
    header: {
      style: {},
    },
    rows: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",
        backgroundColor: "transparent",

        marginBottom: "-1px",

        fontSize: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        fontWeight: "600",
        overflow: "hidden",
        height: "75px",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "10px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
        borderCollapse: "collapse",
      },
    },
    headCells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    cells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
  };
  const sampleData = [
    {
      itemName: "Paint Workshop",
      usersInterested: "20",
      status: "soldOut",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "25",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint1 ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
  ];
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },

    {
      name: "Item Name",
      id: "itemName",
      selector: (row) => row.title,
      grow: 2,
    },
    {
      name: "Created Timestamp",
      id: "createdTimestamp",
      selector: (row) => moment(row.created_at).format("YYYY-MM-DD HH:mm"),
      grow: 3,
    },

    {
      name: "Users Interested",
      id: "usersInterested",
      selector: (row) => row.totalusers,
      grow: 2,
    },

    {
      name: "Status",
      id: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <p
          className={`${
            row.item_sold === false
              ? "bg-[#E2FBD7] text-[#34B53A] p-[12px]"
              : "bg-[#FDCAD1] text-[#FF0023]"
          } font-[600] text-[0.8rem] font-poppins  flex flex-row justify-center items-center h-[2.5rem] w-[8rem]`}
        >
          {row.item_sold === false ? "In Stock" : "Sold Out"}
        </p>
      ),
    },
    {
      name: "Actions",
      id: "actions",
      cell: (row) => (
        <div className="flex gap-[1.5rem] items-center">
          <Link to={`/shop/view-interested-users/${row.product_id}`}>
            <Icon
              icon="carbon:view-filled"
              className="text-[1.9rem] cursor-pointer"
            />
          </Link>
          <Link to={`/shop/edit-item/${row.product_id}`}>
            <Icon
              icon="ic:baseline-edit"
              className="text-[2rem] cursor-pointer"
            />
          </Link>
          <Link>
            <Icon
              icon="material-symbols:delete"
              className="text-[2rem] text-[#B53434] cursor-pointer"
              onClick={() => deleteProduct(row._id)}
            />
          </Link>
        </div>
      ),
      grow: 2,
    },
  ];

  const fetchPaintingsData = async () => {
    // setFetchLoaderBtn(true);
    // setLoader(true);
    setLoad(true);
    try {
      const result = await apiClient.get(FETCH_PAINTINGS);

      if (
        result.status === 400 ||
        result.status === 404 ||
        result.status === 409 ||
        result.status === 500
      ) {
        // setLoader(false);

        return console.log("no events available");
      }
      setLoad(false);
      console.log("SHOP DATA", result?.data?.response?.paintings);
      // setLoader(false);
      setPaintings(result?.data?.response?.paintings);
    } catch (error) {
      setLoad(false);
      // setLoader(false);
      console.log("Something went wrong while finding event");
      return console.log("event fetched failed");
    }
  };

  useEffect(() => {
    fetchPaintingsData();
  }, []);

  return (
    <div className="bg-[#F6F6F6]  px-[1rem] py-[2rem] lg:px-[1.5rem] lg:py-[2rem]">
      <div
        style={{ boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
        className="bg-[#FFF] w-full "
      >
        <div className="px-[1rem] py-[1rem] h-[72px] lg:h-[80px]  lg:p-[1.4rem] flex flex-row justify-between items-center">
          <div className="flex gap-[1.5rem] sm:gap-[2rem] items-center">
            {/* <div
              onClick={() => navigate(-1)}
              className="border border-solid border-[#808080] h-[3rem] w-[3rem] flex justify-center items-center cursor-pointer"
            >
              <Icon
                icon="ic:round-arrow-back-ios-new"
                className="text-[1.5rem]"
              />
            </div> */}
            <h1 className="font-poppins text-[1.2rem] lg:text-[2rem] text-[#060606] font-[600] leading-[1rem] tracking-[-0.2px]">
              Manage Shop
            </h1>
          </div>
          <Link to={"/shop/create-item"}>
            <button className="h-[3rem] text-[#060606] border border-solid border-[#808080] flex justify-center items-center gap-[1rem] px-[1rem] ">
              <p className="hidden lg:block font-poppins text-[1rem] font-bold leading-[1rem] tracking-[1px]">
                Add Item
              </p>
              <Icon
                icon="ic:baseline-edit"
                className="text-[1.5rem] cursor-pointer"
              />
            </button>
          </Link>
        </div>

        <div>
          <DataTable
            columns={columns}
            data={paintings}
            customStyles={customStyles}
            pagination
            progressPending={load}
            // paginationComponent={CustomPagination}
          />
        </div>
      </div>
    </div>
  );
}
