import React, { useState } from 'react'
import axios from 'axios';
import _ from 'lodash'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import { Select, Option } from "@material-tailwind/react";

const Adduser = () => {
  const navigate = useNavigate();

  let message;
  const notifyA = () => toast.success(message);
  const notifyB = () => toast.error(message);

  function verifyEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  function verifyIndianPhoneNumber(phoneNumber) {
    const phoneRegex = /^(\+91|0)?[6789]\d{9}$/;
    return phoneRegex.test(phoneNumber);
  }

  const [datpicker, setdatePicker] = useState(false);
  const [firstname, setfirstName] = useState("");
  const [lastname, setlastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [dateofbirth, setdateofBirth] = useState();
  const [loader, setLoader] = useState(false);

  const handleUser = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      if (_.isEmpty(firstname)) {
        setLoader(false);
        message = "First name cannot be empty";
        return notifyB(message);
      }
      if (_.isEmpty(lastname)) {
        setLoader(false);
        message = "Last name cannot be empty";
        return notifyB(message);
      }

      if (_.isEmpty(email)) {
        setLoader(false);
        message = "Email cannot be empty";
        return notifyB(message);
      }

      if (email) {
        if (!verifyEmail(email)) {
          setLoader(false);
          message = "Enter a valid email";
          return notifyB(message);
        }
      }

      if (_.isEmpty(phone)) {
        setLoader(false);
        message = "Mobile Number cannot be empty";
        return notifyB(message);
      }

      if (phone) {
        if (!verifyIndianPhoneNumber(phone)) {
          setLoader(false);
          message = "Enter a valid mobile number";
          return notifyB(message);
        }
      }

      if (_.isEmpty(dateofbirth)) {
        setLoader(false);
        message = "Date of birth cannot be empty";
        return notifyB(message);
      }

      const result = await axios.post("http://localhost:4000/addnewuser", {
        firstname,
        lastname,
        email,
        phone,
        dateofbirth,
      });

      if (
        result.status === 400 ||
        result.status === 404 ||
        result.status === 409 ||
        result.status === 500
      ) {
        setLoader(false);
        message = "Oops! Request failed to add new user";
        return notifyB(message);
      }

      if (result.status === 201) {
        if (_.isEmpty(result)) {
          setLoader(false);
          message = "Oops! Something went wrong";
          return notifyB(message);
        }

        setLoader(false);
        setfirstName("");
        setlastName("");
        setPhone("");
        setdateofBirth("");
        setEmail("");
        navigate("/records");
        message = "Great! You have successfully added new user";
        return notifyA(message);
      }
    } catch (error) {
      setLoader(false);
      message = "Oops! Something went wrong while adding new user.";
      return notifyB(message);
    }
  };

  return (
    <div className='bg-[#F6F6F6] w-full h-screen py-10 px-4  '>
      <div
        style={{
          boxShadow: "0px 0px 15px 0px #ddd, 0px 4px 16px rgba(0, 0, 0, 0.25) ",
        }}
        className='bg-[#fff] w-full px-4 py-6 flex flex-col gap-7 md:gap-9 md:px-6 md:py-8'>
        <div className=' flex flex-row justify-between items-center '>
          <h1 className='text-[#060606] text-[20px] text-poppins font-[700] leading-[1rem] md:text-[30px] '>
            Create User
          </h1>

          {loader ? (
            <ThreeDots
              height='80'
              width='80'
              radius='9'
              color='#0074be'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClassName=''
              visible={true}
            />
          ) : (
            <button
              onClick={(e) => handleUser(e)}
              className='bg-[#060606] h-[32px] w-[79px] md:h-[60px] md:w-[180px] text-[16px] text-[#F6F6F6] leading-[99%] font-[700] '>
              Save
            </button>
          )}
        </div>
        <form className='flex flex-col '>
          <div className='flex flex-col gap-7 md:gap-9 grid grid-cols-1 md:grid-cols-2'>
            <div>
              <input
                type='text'
                placeholder='First Name'
                value={firstname}
                onChange={(e) => setfirstName(e.target.value)}
                className='h-[72px] w-full outline-none  border border-solid border-[#808080] p-[1rem] placeholder:text-poppins placeholder:text-[16px] placeholder:font-[600] placeholder:text-[#808080] '
              />
            </div>
            <div>
              <input
                type='text'
                placeholder='Last Name'
                value={lastname}
                onChange={(e) => setlastName(e.target.value)}
                className='h-[72px] w-full outline-none border border-solid border-[#808080] p-[1rem] placeholder:text-poppins placeholder:text-[16px] placeholder:font-[600] placeholder:text-[#808080] '
              />
            </div>
            <div className='md:col-span-2 '>
              <input
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Email Id'
                className='h-[72px] w-full outline-none  border border-solid border-[#808080] p-[1rem] placeholder:text-poppins placeholder:text-[16px] placeholder:font-[600] placeholder:text-[#808080] '
              />
            </div>
            <div>
              <input
                type='number'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder='Mobile Number'
                className='h-[72px] w-full outline-none border border-solid border-[#808080] p-[1rem] placeholder:text-poppins placeholder:text-[16px] placeholder:font-[600] placeholder:text-[#808080] '
              />
            </div>
            <div>
              <input
                type={datpicker ? "date" : "text"}
                value={dateofbirth}
                placeholder='Date of birth'
                onFocus={() => setdatePicker(!datpicker)}
                onChange={(e) => setdateofBirth(e.target.value)}
                className='h-[72px] w-full outline-none border border-solid border-[#808080] p-[1rem] placeholder:text-poppins placeholder:text-[16px] placeholder:font-[600] placeholder:text-[#808080] '
              />
            </div>

            <div>
              <input
                type='number'
                placeholder='Age'
                className='h-[72px] w-full outline-none border border-solid border-[#808080] p-[1rem] placeholder:text-poppins placeholder:text-[16px] placeholder:font-[600] placeholder:text-[#808080] '
              />
            </div>

            <div style={{ border: "1px solid #808080" }}>
              <select className='h-[72px] w-full outline-none border-r-[1.2rem] border-solid border-transparent p-[1rem] placeholder:text-poppins placeholder:text-[16px] placeholder:font-[600] placeholder:text-[#808080] '>
                <option value='' selected disabled>
                  State
                </option>
                <option>West Bengal</option>
                <option>Uttar Pradesh</option>
                <option>Delhi</option>
              </select>
            </div>

            <div className=''>
              <textarea
                rows={5}
                placeholder='Message'
                className=' w-full outline-none border border-solid border-[#808080] p-[1rem] placeholder:text-poppins placeholder:text-[16px] placeholder:font-[600] placeholder:text-[#808080] '
              />
            </div>
            <div>
              <input
                type='number'
                placeholder='Pincode'
                className='h-[72px]  w-full outline-none border border-solid border-[#808080] p-[1rem] placeholder:text-poppins placeholder:text-[16px] placeholder:font-[600] placeholder:text-[#808080] '
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Adduser