import React, { useState, useEffect } from "react";
import { Checkbox } from "@material-tailwind/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiClient } from "../../api/apiClient";
import { GET_PAINT_EDIT, EDIT_PAINTINGS } from "../../api/apiConstants";

import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { Icon } from "@iconify/react";

const EditShopItem = () => {
  const { productId } = useParams(); // Assuming you're using React Router
  const [selectImg, setSelectImg] = useState(null);
  const Navigate = useNavigate();

  // Function to fetch product details
  const fetchProductDetails = async () => {
    try {
      const response = await apiClient.get(`${GET_PAINT_EDIT}/${productId}`);
      if (response.status === 200) {
        const { title, description, item_sold, shop_image, summary } =
          response.data.response;
        formik.setFieldValue("title", title);
        formik.setFieldValue("description", description);
        formik.setFieldValue("item_sold", item_sold);
        formik.setFieldValue("summary", summary);
        setSelectImg(shop_image); // Adjust as needed based on your API response

        return response.data.response;
      }
    } catch (error) {
      toast.error("Error fetching product details");

      throw new Error("Something went wrong");
    }
  };

  const {
    data: productData,
    error,
    isLoading,
    refetch,
  } = useQuery("productDetails", async () => await fetchProductDetails(), {
    refetchOnWindowFocus: false,
  });

  const handleImageChange = (event) => {
    if (event.currentTarget.files[0]) {
      setSelectImg(event.currentTarget.files[0]);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      summary: "",
      description: "",
      item_sold: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Required"),
      summary: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("summary", values.summary);
      formData.append("description", values.description);
      formData.append("item_sold", values.item_sold);

      if (selectImg) {
        formData.append("paintings", selectImg);
      }

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
          },
        };
        await apiClient.put(`${EDIT_PAINTINGS}/${productId}`, formData, config);
        toast.success("Product updated successfully!");
        Navigate("/shop");

        refetch();
      } catch (error) {
        toast.error("Error updating product");
      }
    },
  });

  if (isLoading) {
    return (
      <div className="w-full h-[400px] flex justify-center items-center">
        <ThreeDots
          height="80"
          width="130"
          radius="9"
          color="#0074be"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />{" "}
      </div>
    ); // Or any loading component
  }

  if (error) {
    toast.error("Something went wrong !!");
    return Navigate("/shop");
  }

  return (
    <div className="bg-[#F6F6F6] py-[1rem] px-[1rem] lg:px-[2.5rem] lg:py-[4rem]">
      {isLoading ? (
        <div className="w-full h-[400px] flex justify-center items-center">
          <ThreeDots
            height="80"
            width="130"
            radius="9"
            color="#0074be"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />{" "}
        </div>
      ) : (
        <div
          style={{ boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
          className="bg-[#fff] p-[1rem] lg:p-[1.7rem] flex flex-col gap-[1.5rem] lg:gap-[2.5rem]"
        >
          <div className="flex flex-row justify-between items-center font-poppins">
            <div className="flex gap-[1.5rem] sm:gap-[2rem] items-center">
              <div
                onClick={() => Navigate("/shop")}
                className="border border-solid border-[#808080] h-[2rem] lg:h-[3rem] w-[2rem] lg:w-[3rem] flex justify-center items-center cursor-pointer"
              >
                <Icon
                  icon="ic:round-arrow-back-ios-new"
                  className="text-[1.3rem] lg:text-[1.5rem]"
                />
              </div>
              <h1 className="text-[#060606] text-[1.25rem] lg:text-[2rem] leading-[1.5rem] font-[600]">
                Edit Item
              </h1>
            </div>
            <button
              disabled={formik.isSubmitting}
              className="bg-[#060606] h-[2rem] w-[4.8rem] lg:h-[3.5rem] lg:w-[12rem] flex justify-center items-center text-[#F6F6F6] text-[0.8rem] leading-[1rem] font-bold"
              onClick={() => formik.handleSubmit()}
            >
              {formik.isSubmitting ? (
                <ThreeDots size={20} color="#FFF" />
              ) : (
                "SAVE"
              )}
            </button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 gap-[1.5rem]">
              <div className="flex flex-col gap-2">
                <input
                  name="title"
                  placeholder="Item Name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  style={{ border: "1px solid #808080" }}
                  className="h-[4.5rem] w-full p-[1rem] lg:p-[1.5rem] outline-none font-poppins placeholder:text-[#060606] placeholder:text-[1rem] placeholder:font-semibold"
                />
                {formik.touched.title && formik.errors.title && (
                  <div>{formik.errors.title}</div>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <textarea
                  name="summary"
                  rows="4"
                  placeholder="Summary"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.summary}
                  style={{ border: "1px solid #808080" }}
                  className="w-full p-[1rem] lg:p-[1.5rem] outline-none font-poppins placeholder:text-[#060606] placeholder:text-[1rem] placeholder:font-semibold"
                />
                {formik.touched.summary && formik.errors.summary && (
                  <div>{formik.errors.summary}</div>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <textarea
                  name="description"
                  rows="6"
                  placeholder="Description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  style={{ border: "1px solid #808080" }}
                  className="w-full p-[1rem] lg:p-[1.5rem] outline-none font-poppins placeholder:text-[#060606] placeholder:text-[1rem] placeholder:font-semibold"
                />
                {formik.touched.description && formik.errors.description && (
                  <div>{formik.errors.description}</div>
                )}
              </div>
              <div className="flex flex-row items-center gap-3">
                <Checkbox
                  id="item_sold"
                  name="item_sold"
                  checked={formik.values.item_sold}
                  onChange={formik.handleChange}
                />
                <label
                  htmlFor="item_sold"
                  className="font-poppins text-[#000] text-[1rem] lg:text-[1.2rem] font-semibold"
                >
                  Item Sold
                </label>
              </div>
              <div
                style={{ border: "1px solid  #808080" }}
                className="flex justify-between items-center  px-[1rem] py-[1rem] lg:p-[1.5rem]"
              >
                <div className="flex flex-row  justify-center gap-3 xl:gap-[8rem]">
                  {selectImg && (
                    <img
                      src={
                        typeof selectImg === "string"
                          ? selectImg
                          : URL.createObjectURL(selectImg)
                      }
                      alt="Selected"
                      className="w-[4rem] h-[2rem] lg:h-[4rem] lg:w-[8rem] rounded-[5px]"
                    />
                  )}
                  <div className="flex items-center gap-[0.5rem] xl:gap-[1rem]">
                    <h3 className="text-[1rem] text-[#060606] font-bold font-poppins">
                      Upload Image
                    </h3>
                  </div>
                </div>
                <button
                  type="button"
                  className="text-[0.8rem] font-semibold text-white bg-[#060606] py-[0.5rem] px-[2rem] lg:py-[1rem] lg:px-[3rem]"
                  onClick={() => document.querySelector(".input-field").click()}
                >
                  Browse
                </button>
                <input
                  type="file"
                  accept="image/*"
                  className="input-field"
                  hidden
                  onChange={(event) => {
                    if (event.currentTarget.files[0]) {
                      setSelectImg(event.currentTarget.files[0]);
                    }
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditShopItem;
