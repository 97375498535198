import React from "react";
import Globalroute from "./components/Globalroute";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./components/LoginPage/LoginPage";
import Dashboard from "./components/Dashboard";
import Event from "./components/Event";
import Records from "./components/Records";
import Reports from "./components/Reports";
import Tickets from "./components/Tickets";
import Adduser from "./components/Adduser";
import Sendupdates from "./components/Sendupdates";
import Addevent from "./components/Addevent";
import Editevent from "./components/Editevent";
import ProfileSection from "./components/ProfileSection";
import EditTicket from "./components/EditTicket";

import TransactionReports from "./components/reports/TransactionReports";

import EventReports from "./components/reports/EventReports";


import ChangePassword from "./components/ChangePassword";

import EditItem from "./components/shop/EditShopItem";
import QuotationCard from "./components/shop/QuotationCard";

import UserDetails from "./components/UserDetails";
import UserInterested from "./components/UserInterested";
import UserRegistered from "./components/UserRegistered";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./context/PrivateRoute";
import PublicRoute from "./context/PublicRoute";
import EditShopItem from "./components/shop/EditShopItem";
import ShopManagement from "./components/shop/ShopManagement";
import InterestedUsersList from "./components/shop/InterestedUsersList";
import SendQuotation from "./components/shop/SendQuotation";
import AddShopItem from "./components/shop/AddShopItem";

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route
            exact
            path='/login'
            element={<PublicRoute component={LoginPage} />}
          />

          <Route exact path='/' element={<Globalroute />}>
            <Route
              exact
              path='/'
              element={<PrivateRoute component={Dashboard} />}
            />
            <Route
              exact
              path='/event'
              element={<PrivateRoute component={Event} />}
            />
            <Route
              exact
              path='/records'
              element={<PrivateRoute component={Records} />}
            />
            <Route
              exact
              path='/reports'
              element={<PrivateRoute component={Reports} />}
            />
            <Route
              exact
              path='/ticketmanagement'
              element={<PrivateRoute component={Tickets} />}
            />
            <Route
              exact
              path='/adduser'
              element={<PrivateRoute component={Adduser} />}
            />
            <Route
              exact
              path='/sendupdates'
              element={<PrivateRoute component={Sendupdates} />}
            />
            <Route
              exact
              path='/addevent'
              element={<PrivateRoute component={Addevent} />}
            />
            <Route
              exact
              path='/editevent/:eventId'
              element={<PrivateRoute component={Editevent} />}
            />

            <Route
              exact
              path='/transaction-reports'
              element={<PrivateRoute component={TransactionReports} />}
            />
            <Route
              path='/event-reports'
              element={<PrivateRoute component={EventReports} />}
            />

            <Route
              path='/view-ticket/:ticketId'
              element={<PrivateRoute component={EditTicket} />}
            />

            <Route
              path='/profile-edit'
              element={<PrivateRoute component={ProfileSection} />}
            />

            <Route
              path='/shop'
              element={<PrivateRoute component={ShopManagement} />}
            />
            <Route
              path='/shop/create-item'
              element={<PrivateRoute component={AddShopItem} />}
            />
            <Route
              path='/shop/edit-item/:productId'
              element={<PrivateRoute component={EditItem} />}
            />
            <Route
              path='/shop/view-interested-users/:productId'
              element={<PrivateRoute component={InterestedUsersList} />}
            />
            <Route
              path='/shop/view-interested-users/:id/send-quotation'
              element={<PrivateRoute component={SendQuotation} />}
            />

            <Route
              path='shop/edit-item'
              element={<PrivateRoute component={EditShopItem} />}
            />

            <Route
              path='/change-password'
              element={<PrivateRoute component={ChangePassword} />}
            />
            <Route
              path='/send-quotation'
              element={<PrivateRoute component={QuotationCard} />}
            />
          </Route>
        </Routes>
      </Router>

      <ToastContainer />
    </div>
  );
};

export default App;
