import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link, useNavigate ,useParams} from "react-router-dom";
import moment from "moment";
import { apiClient } from "../../api/apiClient";
export default function InterestedUsersList() {
  const navigate = useNavigate();

  
  const [interestedUsers, setInterestedUsers] = useState([]);

  const [load , setLoad ] = useState(false);

  // Assuming 'productId' is passed to this component or retrieved from context/router
  const {productId} = useParams(); // Replace with actual product ID or logic to retrieve it


    // Function to fetch interested users data
    const fetchInterestedUsers = async () => {
      setLoad(true)
      try {
        const response = await apiClient.get(`/api/paintings/users/${productId}`);
        setLoad(false)
        setInterestedUsers(response.data.response.total_enquery); // Adjust according to the actual response format
      } catch (error) {
        setLoad(false)
        console.error("Error fetching interested users:", error);
      }
    };
  
    useEffect(() => {
      fetchInterestedUsers();
    }, []);
  

  const customStyles = {
    headRow: {
      style: {
        fontWeight: "bold",
        border: "none",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",

        fontSize: "20px",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        fontWeight: "800",
        height: "75px",
      },
    },
    header: {
      style: {},
    },
    rows: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",
        backgroundColor: "transparent",

        marginBottom: "-1px",

        fontSize: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        fontWeight: "600",
        overflow: "hidden",
        height: "75px",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "10px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
        borderCollapse: "collapse",
      },
    },
    headCells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    cells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
  };
  const sampleData = [
    {
      itemName: "Paint Workshop",
      usersInterested: "20",
      status: "soldOut",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "25",
      status: "inStock",
    },
    {
      itemName: "Paint Workshop",
      usersInterested: "20",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "25",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "25",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "25",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "25",
      status: "inStock",
    },

    {
      itemName: "Sip & Paint ",
      usersInterested: "25",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "25",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint1 ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint1 ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "30",
      status: "inStock",
    },
    {
      itemName: "Sip & Paint ",
      usersInterested: "25",
      status: "inStock",
    },
  ];
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },

    {
      name: "Name",
      selector: (row) => row.name,
      grow: 2,
    },
    {
      name: "Created Timestamp",
      selector: (row) => moment(row.created_At).format("YYYY-MM-DD HH:mm:ss"),
      grow: 3,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.phone,
      grow: 2,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      grow: 2,
    },

    {
      name: "Item Name",
      selector: (row) => row.item_id.title,
      grow: 2,
    },
    {
      name: "Actions",
      id: "actions",
      cell: (row) => (
        <div className="flex gap-[1.5rem] items-center">
          <Link to={"/shop/view-interested-users/:id/send-quotation"}>
            <Icon
              icon="material-symbols:send"
              className="text-[2rem] cursor-pointer"
            />
          </Link>
          <Link>
            <Icon
              icon="material-symbols:delete"
              className="text-[2rem] text-[#B53434] cursor-pointer"
            />
          </Link>
        </div>
      ),
      grow: 2,
    },
  ];

  return (
    <div className="bg-[#F6F6F6]  px-[1rem] py-[2rem] lg:px-[1.5rem] lg:py-[2rem]">
      <div
        style={{ boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
        className="bg-[#FFF] w-full "
      >
        <div className="px-[1rem] py-[1rem] h-[72px] lg:h-[80px]  lg:p-[1.4rem] flex flex-row justify-between items-center">
          <h1 className="font-poppins text-[1.2rem] lg:text-[2rem] text-[#060606] font-[600] leading-[1rem] tracking-[-0.2px]">
            Interested Users
          </h1>
          <button className="h-[3rem] text-[#060606] border border-solid border-[#808080] flex justify-center items-center gap-[1rem] px-[1rem] ">
            <p className="hidden lg:block font-poppins text-[1rem] font-bold leading-[1rem] tracking-[1px]">
              Send Quotation
            </p>
            <Icon
              icon="material-symbols:send"
              className="text-[1.5rem] cursor-pointer"
            />
          </button>
        </div>

        <div>
          <DataTable
            columns={columns}
            data={interestedUsers}
            customStyles={customStyles}
            pagination
            progressPending={load}
            // paginationComponent={CustomPagination}
          />
        </div>
      </div>
    </div>
  );
}

