import React, { Suspense } from "react";
import { Route, Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const PublicRoute = ({ component: Component, ...props }) => {
  const { currentUser } = useAuth();

  return currentUser ? (
    <Navigate to="/" />
  ) : (
    <Suspense fallback={"LOading.."}>
      <Component {...props} />
    </Suspense>
  );
};

export default PublicRoute;
