import React, { useState } from 'react'
import logo from '../assets/logo.png'
import navimg from '../assets/navimg.png'
import { NavLink } from 'react-router-dom'

const Navbar = () => {

    const [open, setOpen] = useState(false);
    const [openevent, setOpenevent] = useState(false);

    return (
      <nav>
        <div
          className='nav-container flex justify-between items-center  h-[67px] 
                            md:h-[90px] lg:h-[120px]  bg-[#F6F6F6] 
                            p-4 lg:px-5 md:py-[32px] text-[#060606] font-poppins relative '>
          <div className='hamburger lg:hidden' onClick={() => setOpen(!open)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'>
              <path
                d='M4 18.5C3.71667 18.5 3.479 18.404 3.287 18.212C3.095 18.02 2.99934 17.7827 3 17.5C3 17.2167 3.096 16.979 3.288 16.787C3.48 16.595 3.71734 16.4993 4 16.5H20C20.2833 16.5 20.521 16.596 20.713 16.788C20.905 16.98 21.0007 17.2173 21 17.5C21 17.7833 20.904 18.021 20.712 18.213C20.52 18.405 20.2827 18.5007 20 18.5H4ZM4 13.5C3.71667 13.5 3.479 13.404 3.287 13.212C3.095 13.02 2.99934 12.7827 3 12.5C3 12.2167 3.096 11.979 3.288 11.787C3.48 11.595 3.71734 11.4993 4 11.5H20C20.2833 11.5 20.521 11.596 20.713 11.788C20.905 11.98 21.0007 12.2173 21 12.5C21 12.7833 20.904 13.021 20.712 13.213C20.52 13.405 20.2827 13.5007 20 13.5H4ZM4 8.5C3.71667 8.5 3.479 8.404 3.287 8.212C3.095 8.02 2.99934 7.78267 3 7.5C3 7.21667 3.096 6.979 3.288 6.787C3.48 6.595 3.71734 6.49934 4 6.5H20C20.2833 6.5 20.521 6.596 20.713 6.788C20.905 6.98 21.0007 7.21734 21 7.5C21 7.78334 20.904 8.021 20.712 8.213C20.52 8.405 20.2827 8.50067 20 8.5H4Z'
                fill='#101010'
              />
            </svg>
          </div>

          <div className='hidden border-[1px] border-[#808080] p-[12px] relative lg:flex justify-between '>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='absolute'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'>
              <path
                d='M9.5 16C7.68333 16 6.146 15.3707 4.888 14.112C3.63 12.8533 3.00067 11.316 3 9.5C3 7.68333 3.62933 6.146 4.888 4.888C6.14667 3.63 7.684 3.00067 9.5 3C11.3167 3 12.854 3.62933 14.112 4.888C15.37 6.14667 15.9993 7.684 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L20.3 18.9C20.4833 19.0833 20.575 19.3167 20.575 19.6C20.575 19.8833 20.4833 20.1167 20.3 20.3C20.1167 20.4833 19.8833 20.575 19.6 20.575C19.3167 20.575 19.0833 20.4833 18.9 20.3L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16ZM9.5 14C10.75 14 11.8127 13.5623 12.688 12.687C13.5633 11.8117 14.0007 10.7493 14 9.5C14 8.25 13.5623 7.18733 12.687 6.312C11.8117 5.43667 10.7493 4.99933 9.5 5C8.25 5 7.18733 5.43767 6.312 6.313C5.43667 7.18833 4.99933 8.25067 5 9.5C5 10.75 5.43767 11.8127 6.313 12.688C7.18833 13.5633 8.25067 14.0007 9.5 14Z'
                fill='#060606'
              />
            </svg>

            <input
              type='text'
              placeholder='search'
              className='pl-8 outline-none text-[14px] font-[600] tracking-[-0.14px] uppercase bg-[#F6F6F6] '
            />
          </div>

          <div className='flex flex-row gap-[2rem]'>
            <NavLink to='/'>
              {" "}
              <img
                src={logo}
                alt=''
                className='w-[85px] h-[37px] lg:hidden'
              />{" "}
            </NavLink>
            <div className='logo flex gap-10'>
              <div className='profile flex gap-1'>
                <img
                  src={navimg}
                  alt=''
                  className='w-[37px] lg:w-[64px]  h-[37px] lg:h-[64px]'
                />
                <div className='name-text'>
                  <p className='text-[12px] lg:text-[24px] font-[600] tracking-[1.32px] lg:tracking-[2.64px] '>
                    Manoghnya
                  </p>
                  <p className='text-[8px] lg:text-[16px] font-[400] tracking-[0.88px] lg:tracking-[1.76px]'>
                    Administrator
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {open ? (
          <div className='w-[320px] lg:hidden  flex flex-col justify-between h-screen bg-[#F6F6F6] top-0 absolute left-0 z-50 px-[24px] pt-[24px] pb-[32px]'>
            <div className='top-conatiner'>
              <div
                className='logo-conatiner flex justify-between items-center '
                onClick={() => setOpen(!open)}>
                <p></p>
                <img
                  src={logo}
                  alt=''
                  className='w[85px] h-[37px] object-cover'
                />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='25'
                  onClick={() => setOpen(!open)}
                  viewBox='0 0 24 25'
                  fill='none'>
                  <path
                    d='M12.0008 13.8998L7.10078 18.7998C6.91745 18.9831 6.68411 19.0748 6.40078 19.0748C6.11745 19.0748 5.88411 18.9831 5.70078 18.7998C5.51745 18.6165 5.42578 18.3831 5.42578 18.0998C5.42578 17.8165 5.51745 17.5831 5.70078 17.3998L10.6008 12.4998L5.70078 7.5998C5.51745 7.41647 5.42578 7.18314 5.42578 6.8998C5.42578 6.61647 5.51745 6.38314 5.70078 6.1998C5.88411 6.01647 6.11745 5.9248 6.40078 5.9248C6.68411 5.9248 6.91745 6.01647 7.10078 6.1998L12.0008 11.0998L16.9008 6.1998C17.0841 6.01647 17.3174 5.9248 17.6008 5.9248C17.8841 5.9248 18.1174 6.01647 18.3008 6.1998C18.4841 6.38314 18.5758 6.61647 18.5758 6.8998C18.5758 7.18314 18.4841 7.41647 18.3008 7.5998L13.4008 12.4998L18.3008 17.3998C18.4841 17.5831 18.5758 17.8165 18.5758 18.0998C18.5758 18.3831 18.4841 18.6165 18.3008 18.7998C18.1174 18.9831 17.8841 19.0748 17.6008 19.0748C17.3174 19.0748 17.0841 18.9831 16.9008 18.7998L12.0008 13.8998Z'
                    fill='#060606'
                  />
                </svg>
              </div>

              <div className=' border-[1px] border-[#808080] p-[12px] mt-10 relative flex justify-between  w-[272px]'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='absolute'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'>
                  <path
                    d='M9.5 16C7.68333 16 6.146 15.3707 4.888 14.112C3.63 12.8533 3.00067 11.316 3 9.5C3 7.68333 3.62933 6.146 4.888 4.888C6.14667 3.63 7.684 3.00067 9.5 3C11.3167 3 12.854 3.62933 14.112 4.888C15.37 6.14667 15.9993 7.684 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L20.3 18.9C20.4833 19.0833 20.575 19.3167 20.575 19.6C20.575 19.8833 20.4833 20.1167 20.3 20.3C20.1167 20.4833 19.8833 20.575 19.6 20.575C19.3167 20.575 19.0833 20.4833 18.9 20.3L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16ZM9.5 14C10.75 14 11.8127 13.5623 12.688 12.687C13.5633 11.8117 14.0007 10.7493 14 9.5C14 8.25 13.5623 7.18733 12.687 6.312C11.8117 5.43667 10.7493 4.99933 9.5 5C8.25 5 7.18733 5.43767 6.312 6.313C5.43667 7.18833 4.99933 8.25067 5 9.5C5 10.75 5.43767 11.8127 6.313 12.688C7.18833 13.5633 8.25067 14.0007 9.5 14Z'
                    fill='#060606'
                  />
                </svg>
                <input
                  type='text'
                  placeholder='search..'
                  className='outline-none pl-8 text-[14px] font-[600] tracking-[-0.14px] uppercase bg-[#F6F6F6] '
                />
              </div>

              <div className='nav-NavLinks pt-5'>
                <NavLink
                  to='/'
                  className={({ isActive, isPending }) =>
                    `  nav w-full cursor-pointer py-4 my-3 text-[#060606] flex items-center gap-4 group  ${
                      isActive
                        ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                        : "text-[#060606]"
                    }   `
                  }
                  end
                  onClick={() => setOpen(!open)}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'>
                    <path
                      d='M13 9V3H21V9H13ZM3 13V3H11V13H3ZM13 21V11H21V21H13ZM3 21V15H11V21H3Z'
                      fill='currentColor'
                    />
                  </svg>
                  <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                    DASHBOARD
                  </p>
                </NavLink>

                <div
                  className={`w-full cursor-pointer py-4 my-3 text-[#060606] flex items-center gap-4 group ${
                    openevent ? "bg-[#D9D9D9] pl-2" : ""
                  }`}
                  onClick={() => setOpenevent(!openevent)}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    className='text-[#060606]'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'>
                    <path
                      d='M14.5 18C13.8 18 13.2083 17.7583 12.725 17.275C12.2417 16.7917 12 16.2 12 15.5C12 14.8 12.2417 14.2083 12.725 13.725C13.2083 13.2417 13.8 13 14.5 13C15.2 13 15.7917 13.2417 16.275 13.725C16.7583 14.2083 17 14.8 17 15.5C17 16.2 16.7583 16.7917 16.275 17.275C15.7917 17.7583 15.2 18 14.5 18ZM5 22C4.45 22 3.979 21.804 3.587 21.412C3.195 21.02 2.99934 20.5493 3 20V6C3 5.45 3.196 4.979 3.588 4.587C3.98 4.195 4.45067 3.99933 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.805 4.98 21.0007 5.45067 21 6V20C21 20.55 20.804 21.021 20.412 21.413C20.02 21.805 19.5493 22.0007 19 22H5ZM5 20H19V10H5V20Z'
                      fill='currentColor'
                    />
                  </svg>
                  <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                    Event Management
                  </p>
                  {openevent ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'>
                      <path
                        d='M3.42501 15.8504L11.825 7.42541C11.925 7.32541 12.0333 7.25441 12.15 7.21241C12.2667 7.17041 12.3917 7.14974 12.525 7.15041C12.6583 7.15041 12.7833 7.17107 12.9 7.21241C13.0167 7.25374 13.125 7.32474 13.225 7.42541L21.65 15.8504C21.8833 16.0837 22 16.3754 22 16.7254C22 17.0754 21.875 17.3754 21.625 17.6254C21.375 17.8754 21.0833 18.0004 20.75 18.0004C20.4167 18.0004 20.125 17.8754 19.875 17.6254L12.525 10.2754L5.17501 17.6254C4.94167 17.8587 4.65401 17.9754 4.31201 17.9754C3.97001 17.9754 3.67434 17.8504 3.42501 17.6004C3.17501 17.3504 3.05001 17.0587 3.05001 16.7254C3.05001 16.3921 3.17501 16.1004 3.42501 15.8504Z'
                        fill='#060606'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'>
                      <path
                        d='M3.42501 9.29998L11.825 17.725C11.925 17.825 12.0333 17.896 12.15 17.938C12.2667 17.98 12.3917 18.0007 12.525 18C12.6583 18 12.7833 17.9793 12.9 17.938C13.0167 17.8967 13.125 17.8257 13.225 17.725L21.65 9.29998C21.8833 9.06665 22 8.77498 22 8.42498C22 8.07498 21.875 7.77498 21.625 7.52498C21.375 7.27498 21.0833 7.14998 20.75 7.14998C20.4167 7.14998 20.125 7.27498 19.875 7.52498L12.525 14.875L5.17501 7.52498C4.94167 7.29165 4.65401 7.17498 4.31201 7.17498C3.97001 7.17498 3.67434 7.29998 3.42501 7.54998C3.17501 7.79998 3.05001 8.09165 3.05001 8.42498C3.05001 8.75832 3.17501 9.04998 3.42501 9.29998Z'
                        fill='#060606'
                      />
                    </svg>
                  )}
                </div>

                {openevent ? (
                  <div className='px-6'>
                    <NavLink
                      to='/addevent'
                      className={({ isActive, isPending }) =>
                        `  nav w-full cursor-pointer py-4 my-3 text-[#060606] flex items-center gap-4 group  ${
                          isActive
                            ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                            : "text-[#060606]"
                        }   `
                      }
                      end
                      onClick={() => setOpen(!open)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'>
                        <path
                          d='M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.4217 2.75 18.8923 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.655 21.1083 7.11733 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z'
                          fill='currentColor'
                        />
                      </svg>
                      <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                        Create events
                      </p>
                    </NavLink>

                    <NavLink
                      to='/event'
                      className={({ isActive, isPending }) =>
                        `  nav w-full cursor-pointer py-4 my-3 text-[#060606] flex items-center gap-4 group  ${
                          isActive
                            ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                            : "text-[#060606]"
                        }   `
                      }
                      end
                      onClick={() => setOpen(!open)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'>
                        <path
                          d='M14.5 18C13.8 18 13.2083 17.7583 12.725 17.275C12.2417 16.7917 12 16.2 12 15.5C12 14.8 12.2417 14.2083 12.725 13.725C13.2083 13.2417 13.8 13 14.5 13C15.2 13 15.7917 13.2417 16.275 13.725C16.7583 14.2083 17 14.8 17 15.5C17 16.2 16.7583 16.7917 16.275 17.275C15.7917 17.7583 15.2 18 14.5 18ZM5 22C4.45 22 3.979 21.804 3.587 21.412C3.195 21.02 2.99934 20.5493 3 20V6C3 5.45 3.196 4.979 3.588 4.587C3.98 4.195 4.45067 3.99933 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.805 4.98 21.0007 5.45067 21 6V20C21 20.55 20.804 21.021 20.412 21.413C20.02 21.805 19.5493 22.0007 19 22H5ZM5 20H19V10H5V20Z'
                          fill='currentColor'
                        />
                      </svg>
                      <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                        View All Events
                      </p>
                    </NavLink>
                  </div>
                ) : (
                  ""
                )}

                <NavLink
                  to='/records'
                  className={({ isActive, isPending }) =>
                    `  nav w-full cursor-pointer py-4 my-3 text-[#060606] flex items-center gap-4 group  ${
                      isActive
                        ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                        : "text-[#060606]"
                    }   `
                  }
                  end>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    className=''
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'>
                    <path
                      d='M12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM9.5 13.5C10.4667 13.5 11.2917 13.1583 11.975 12.475C12.6583 11.7917 13 10.9667 13 10C13 9.03333 12.6583 8.20833 11.975 7.525C11.2917 6.84167 10.4667 6.5 9.5 6.5C8.53333 6.5 7.70833 6.84167 7.025 7.525C6.34167 8.20833 6 9.03333 6 10C6 10.9667 6.34167 11.7917 7.025 12.475C7.70833 13.1583 8.53333 13.5 9.5 13.5ZM16.5 14.5C17.2 14.5 17.7917 14.2583 18.275 13.775C18.7583 13.2917 19 12.7 19 12C19 11.3 18.7583 10.7083 18.275 10.225C17.7917 9.74167 17.2 9.5 16.5 9.5C15.8 9.5 15.2083 9.74167 14.725 10.225C14.2417 10.7083 14 11.3 14 12C14 12.7 14.2417 13.2917 14.725 13.775C15.2083 14.2583 15.8 14.5 16.5 14.5ZM12 20C13.4167 20 14.7083 19.6667 15.875 19C17.0417 18.3333 17.9833 17.45 18.7 16.35C18.35 16.25 17.9917 16.1667 17.625 16.1C17.2583 16.0333 16.8833 16 16.5 16C15.6167 16 14.55 16.2583 13.3 16.775C12.05 17.2917 11.05 18.3083 10.3 19.825C10.5833 19.8917 10.8667 19.9377 11.15 19.963C11.4333 19.9883 11.7167 20.0007 12 20ZM8.825 19.35C9.40833 18.15 10.071 17.2583 10.813 16.675C11.555 16.0917 12.1173 15.7 12.5 15.5C12.0167 15.35 11.529 15.229 11.037 15.137C10.545 15.045 10.0327 14.9993 9.5 15C8.75 15 8.00833 15.0917 7.275 15.275C6.54167 15.4583 5.83333 15.7167 5.15 16.05C5.58333 16.7667 6.11267 17.4127 6.738 17.988C7.36333 18.5633 8.059 19.0173 8.825 19.35Z'
                      fill='currentColor'
                    />
                  </svg>
                  <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                    User Records
                  </p>
                </NavLink>

                <NavLink
                  to='/sendupdates'
                  className={({ isActive, isPending }) =>
                    `  nav w-full cursor-pointer py-4 my-3 text-[#060606] flex items-center gap-4 group  ${
                      isActive
                        ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                        : "text-[#060606]"
                    }   `
                  }
                  end
                  onClick={() => setOpen(!open)}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'>
                    <path
                      d='M3 20V14L11 12L3 10V4L22 12L3 20Z'
                      fill='currentColor'
                    />
                  </svg>
                  <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                    Send Updates
                  </p>
                </NavLink>

                <NavLink
                  to='/reports'
                  className={({ isActive, isPending }) =>
                    `  nav w-full cursor-pointer py-4 my-3 text-[#060606] flex items-center gap-4 group  ${
                      isActive
                        ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                        : "text-[#060606]"
                    }   `
                  }
                  end
                  onClick={() => setOpen(!open)}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'>
                    <path
                      d='M16 11.78L20.24 4.45L21.97 5.45L16.74 14.5L10.23 10.75L5.46 19H22V21H2V3H4V17.54L9.5 8L16 11.78Z'
                      fill='currentColor'
                    />
                  </svg>
                  <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                    Reports
                  </p>
                </NavLink>
              </div>
            </div>

            <div className='bottom'>
              <NavLink
                to='/ticketmanagement'
                className={({ isActive, isPending }) =>
                  `  nav w-full cursor-pointer py-4 my-3 text-[#060606] flex items-center gap-4 group  ${
                    isActive
                      ? "bg-[#0074BE] text-[#fff] px-[12px]  "
                      : "text-[#060606]"
                  }   `
                }
                end
                onClick={() => setOpen(!open)}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'>
                  <path
                    d='M12 22L11.75 19H11.5C9.13333 19 7.125 18.175 5.475 16.525C3.825 14.875 3 12.8667 3 10.5C3 8.13333 3.825 6.125 5.475 4.475C7.125 2.825 9.13333 2 11.5 2C12.6833 2 13.7877 2.22067 14.813 2.662C15.8383 3.10333 16.7383 3.71167 17.513 4.487C18.2877 5.26233 18.8957 6.16233 19.337 7.187C19.7783 8.21167 19.9993 9.316 20 10.5C20 11.75 19.7957 12.95 19.387 14.1C18.9783 15.25 18.42 16.3167 17.712 17.3C17.004 18.2833 16.1623 19.175 15.187 19.975C14.2117 20.775 13.1493 21.45 12 22ZM11.475 15.975C11.7583 15.975 12 15.875 12.2 15.675C12.4 15.475 12.5 15.2333 12.5 14.95C12.5 14.6667 12.4 14.425 12.2 14.225C12 14.025 11.7583 13.925 11.475 13.925C11.1917 13.925 10.95 14.025 10.75 14.225C10.55 14.425 10.45 14.6667 10.45 14.95C10.45 15.2333 10.55 15.475 10.75 15.675C10.95 15.875 11.1917 15.975 11.475 15.975ZM10.75 12.8H12.25C12.25 12.3 12.3 11.95 12.4 11.75C12.5 11.55 12.8167 11.1833 13.35 10.65C13.65 10.35 13.9 10.025 14.1 9.675C14.3 9.325 14.4 8.95 14.4 8.55C14.4 7.7 14.1123 7.06233 13.537 6.637C12.9617 6.21167 12.2827 5.99933 11.5 6C10.7667 6 10.15 6.20433 9.65 6.613C9.15 7.02167 8.8 7.51733 8.6 8.1L10 8.65C10.0833 8.36667 10.2417 8.08733 10.475 7.812C10.7083 7.53667 11.05 7.39933 11.5 7.4C11.95 7.4 12.2873 7.525 12.512 7.775C12.7367 8.025 12.8493 8.3 12.85 8.6C12.85 8.88333 12.7667 9.13767 12.6 9.363C12.4333 9.58833 12.2333 9.81733 12 10.05C11.4167 10.55 11.0623 10.946 10.937 11.238C10.8117 11.53 10.7493 12.0507 10.75 12.8Z'
                    fill='currentColor'
                  />
                </svg>
                <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                  Ticket Management
                </p>
              </NavLink>

              <div className='nav w-full cursor-pointer py-4 text-[#060606] flex items-center gap-4 group'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  className='text-[#060606] group-hover:text-white'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'>
                  <path
                    d='M19 21H10C8.89543 21 8 20.1046 8 19V15H10V19H19V5H10V9H8V5C8 3.89543 8.89543 3 10 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM12 16V13H3V11H12V8L17 12L12 16Z'
                    fill='currentColor'
                  />
                </svg>
                <p className='text-[14px] font-[600] font-poppins uppercase tracking-[-0.14px]'>
                  Log Out
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </nav>
    );
}

export default Navbar