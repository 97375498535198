import { useState } from "react";
import { Icon } from "@iconify/react";
import { Checkbox } from "@material-tailwind/react";

const SendQuotation = () => {
  const [selectImg, setSelectImg] = useState();
  return (
    <div className='bg-[#F6F6F6] py-[1rem] px-[1rem] lg:px-[2.5rem] lg:py-[4rem]'>
      <div
        style={{ boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
        className='bg-[#fff] p-[1rem] lg:p-[1.7rem] flex flex-col gap-[1.5rem] lg:gap-[2.5rem]'>
        <div className='flex flex-row justify-between items-center font-poppins'>
          <h1 className='text-[#060606] text-[1.25rem] lg:text-[2rem] leading-[1.5rem] font-[600]'>
            Send Quotation
          </h1>

          <button className='bg-[#060606] h-[2rem] w-[4.8rem] text-[0.8rem] lg:h-[3.5rem] lg:w-[12rem] text-[#F6F6F6] leading-[1rem] font-bold'>
            Save
          </button>
        </div>
        <form>
          <div className='grid grid-cols-1 gap-[1.5rem]'>
            <div className='flex flex-col gap-2'>
              <input
                placeholder='Item Name'
                type='text'
                style={{ border: "1px solid #808080" }}
                className='h-[4.5rem] w-full p-[1rem] lg:p-[1.5rem] outline-none font-poppins placeholder:text-[#060606] placeholder:text-[1rem] placeholder:font-semibold'
              />
            </div>

            <div className='flex flex-col gap-[1rem]'>
              <div className='flex flex-col gap-2'>
                <textarea
                  rows='6'
                  placeholder='Description'
                  style={{ border: "1px solid #808080" }}
                  className=' w-full p-[1rem] lg:p-[1.5rem] outline-none  font-poppins placeholder:text-[#060606] placeholder:text-[1rem] placeholder:font-semibold'
                />
              </div>
            </div>
            <div
              style={{ border: "1px solid  #808080" }}
              className='flex justify-between items-center  px-[1rem] py-[1rem] lg:p-[1.5rem] '>
              <div className='flex flex-row  justify-center gap-3 xl:gap-[8rem]'>
                {selectImg?.name && (
                  <img
                    src={(selectImg && URL.createObjectURL(selectImg)) || ""}
                    alt=''
                    className='w-[4rem] h-[2rem] lg:h-[4rem] lg:w-[8rem] rounded-[5px]'
                  />
                )}

                <div className='flex items-center gap-[0.5rem] xl:gap-[1rem]'>
                  {selectImg?.name ? (
                    <>
                      <h3 className='text-[0.7rem] lg:text-[1.2rem] text-black font-[700] '>
                        {selectImg && selectImg.name.substring(0, 5) + ".png"}
                        {/* {selectImg?.name.length > 30
                          ? selectImg.name.substring(0, 5) + "..."
                          : selectImg.name} */}
                      </h3>
                      <Icon
                        onClick={() => setSelectImg(null)}
                        icon='akar-icons:cross'
                        className='text-[0.8rem] lg:text-[1.5rem]  cursor-pointer hover:bg-[#3D4A5C]/[0.3]'
                      />
                    </>
                  ) : (
                    <h3 className='text-[1rem] text-[#060606] font-bold font-poppins'>
                      Upload Image
                    </h3>
                  )}
                </div>
              </div>
              <button
                type='button'
                className='text-[0.8rem] font-semibold text-white bg-[#060606] py-[0.5rem] px-[2rem]  
                lg:py-[1rem] lg:px-[3rem]
            '
                onClick={() => document.querySelector(".input-field").click()}>
                Browse
              </button>
              <input
                type='file'
                accept='image/*'
                className='input-field'
                hidden
                onChange={({ target: { files } }) => {
                  if (files[0]) {
                    setSelectImg(files[0]);
                  }
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendQuotation;

