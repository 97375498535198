import React, { Suspense } from 'react';
import { Route,Navigate,} from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ component: Component, ...props }) => {
  const { currentUser } = useAuth();

 
        return currentUser ? <Suspense fallback={"LOading.."}><Component {...props} /> </Suspense> : <Navigate to="/login" />;
        // return true ? <Suspense fallback={"LOading.."}><Component {...props} /> </Suspense> : <Navigate to="/login" />;
  

};

export default PrivateRoute;