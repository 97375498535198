import React, { useState } from "react";
import axios from "axios";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { Select, Option } from "@material-tailwind/react";
import { useFormik } from "formik";
import AddEventSchemas from "../validationSchema/AddEventSchemas";
import { ADD_EVENT } from "../api/apiConstants";
import { apiClient, postData } from "../api/apiClient";
import { Icon } from "@iconify/react";

const Addevent = () => {
  const Navigate = useNavigate();

  let message;
  const notifyA = () => toast.success(message);
  const notifyB = () => toast.error(message);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [date, setDate] = useState("");
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("");
  const [image, setImage] = useState(null);
  // const [imageurl, setImageUrl] = useState(null);
  const [loader, setLoader] = useState(false);
  const [fieldValue, setFieldValue] = useState(null);

  const { values, handleChange, handleSubmit, errors, touched, resetForm } =
    useFormik({
      initialValues: {
        title: "",
        description: "",
        location: "",
        expiryDate: "",
        price: "",
        type: "",
      },

      validationSchema: AddEventSchemas,

      onSubmit: async (values) => {
        console.log(values);
        console.log("hello", fieldValue);

        const datas = {
          title: values.title,
          description: values.description,
          location: values.location,
          expiryDate: values.expiryDate,
          price: values.price,
          type: values.type,
          eventImage: fieldValue,
        };

        if (_.isEmpty(datas?.eventImage?.name)) {
          toast.warning("EVENT IMAGE CANNOT BE EMPTY !");
          return;
        }

        const formData = new FormData();
        formData.append("title", datas.title);
        formData.append("description", datas.description);
        formData.append("location", datas.location);
        formData.append("expiryDate", datas.expiryDate);
        formData.append("price", datas.price);
        formData.append("type", datas.type);
        formData.append("eventImage", datas.eventImage);

        console.log(formData);

        try {
          setLoader(true);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
            },
          };

          const response = await apiClient.post(ADD_EVENT, formData, config);

          console.log(response);
          setLoader(false);
          if (response.status === 201) {
            toast.success("EVENT CREATED SUCCESSFULLY !");
            console.log(response);
            resetForm();
          }
        } catch (e) {
          toast.error("SOMETHING WENT WRONG CREATING EVENT !");
          setLoader(false);
          console.log(e);
        }

        //setButtLoading(true)

        //formPay.current.submit()
        // resetForm()
      },
    });

  return (
    <div className="w-full   py-10 px-4 bg-[#F6F6F6]">
      {loader ? (
        <div className="w-full h-[400px] flex justify-center items-center">
          <ThreeDots
            height="80"
            width="130"
            radius="9"
            color="#0074be"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />{" "}
        </div>
      ) : (
        <div
          style={{ boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
          className="bg-[#FFF] py-[1rem] px-[1rem] md:px-[1.5rem] md:py-[1.5rem]"
        >
          <form onSubmit={handleSubmit} className="flex flex-col gap-[1.5rem]">
            <div className="flex flex-row justify-between items-center">
              <div className="flex gap-[1.5rem] sm:gap-[2rem] items-center">
                <div
                  onClick={() => Navigate(-1)}
                  className="border border-solid border-[#808080] h-[2rem] lg:h-[3rem] w-[2rem] lg:w-[3rem] flex justify-center items-center cursor-pointer"
                >
                  <Icon
                    icon="ic:round-arrow-back-ios-new"
                    className="text-[1.3rem] lg:text-[1.5rem]"
                  />
                </div>
                <h1 className="font-poppins text-[1.2rem] lg:text-[2rem] font-[600] leading-[1.5rem] ">
                  Create Event
                </h1>
              </div>
              <button
                type="submit"
                className="bg-[#060606] h-[2rem] w-[4.8rem] lg:h-[3.5rem] lg:w-[12rem] text-[0.8rem] font-poppins font-bold text-[#F6F6F6]"
              >
                Save
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[1rem] lg:gap-x-[4rem] lg:gap-y-[3rem]">
              <div className="flex flex-col gap-1">
                <input
                  type="text"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  placeholder="Event Name"
                  className="h-[72px] p-[1.5rem] outline-none w-full bg-transparent placeholder:text-[#808080] placeholder:font-poppins placeholder:text-[1rem] placeholder:font-[500] border border-solid border-[#808080]"
                />

                {errors.title && touched.title ? (
                  <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                    *{errors.title}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-1">
                <input
                  type="text"
                  name="location"
                  value={values.location}
                  onChange={handleChange}
                  placeholder="Event Location"
                  className="h-[72px] p-[1.5rem] outline-none w-full bg-transparent placeholder:text-[#808080] placeholder:font-poppins placeholder:text-[1rem] placeholder:font-[500] border border-solid border-[#808080]"
                />

                {errors.location && touched.location ? (
                  <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                    *{errors.location}
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col lg:row-span-2  gap-1">
                <textarea
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  placeholder="Message"
                  className=" p-[1.5rem] h-[190px] md:h-[170px] lg:h-auto lg:grow outline-none w-full bg-transparent placeholder:text-[#808080] placeholder:font-poppins placeholder:text-[1rem] placeholder:font-[500] border border-solid border-[#808080]"
                />

                {errors.description && touched.description ? (
                  <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                    *{errors.description}
                  </div>
                ) : null}
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-[1rem]">
                <div className="flex flex-col gap-1">
                  <input
                    type="datetime-local"
                    name="expiryDate"
                    value={values.expiryDate}
                    onChange={handleChange}
                    placeholder="Event Date"
                    className="h-[72px] p-[1.5rem] outline-none w-full bg-transparent placeholder:text-[#808080] placeholder:font-poppins placeholder:text-[1rem] placeholder:font-[500] border border-solid border-[#808080]"
                  />

                  {errors.expiryDate && touched.expiryDate ? (
                    <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                      *{errors.expiryDate}
                    </div>
                  ) : null}
                </div>

                <div className="flex flex-col gap-1">
                  <input
                    type="number"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                    placeholder="Price"
                    className="h-[72px] p-[1.5rem] outline-none w-full bg-transparent placeholder:text-[#808080] placeholder:font-poppins placeholder:text-[1rem] placeholder:font-[500] border border-solid border-[#808080]"
                  />

                  {errors.price && touched.price ? (
                    <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                      *{errors.price}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div style={{ border: "1px solid #808080" }}>
                  <select
                    style={{ borderRight: "20px solid transparent" }}
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    className="h-[72px] w-full form-select   outline-none border-none p-[1rem] pr-[2rem] placeholder:text-[16px] placeholder-[#808080] placeholder:font-[500]"
                  >
                    <option>Event Type</option>
                    <option value={"sip & paint"}>sip & paint</option>
                    <option value={"therapeutic art workshops"}>
                      therapeutic art workshops
                    </option>
                    <option value={"art competitions"}>art competitions</option>
                    <option value={"Private events"}>Private events</option>
                    <option value={"Corporate events"}>Corporate events</option>
                  </select>
                </div>

                {errors.type && touched.type ? (
                  <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                    *{errors.type}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-row justify-between    md:mb-5 px-[1rem] items-center mb-3 h-[72px] w-full border border-solid border-[#808080]  ">
                <div className="custom-file-upload text-[16px] font-[500] text-[#808080]">
                  <input
                    style={{ display: "none" }}
                    type="file"
                    className="input-field w-full"
                    name="eventImage"
                    onChange={(event) => {
                      setFieldValue(event.target.files[0]);
                    }}
                  />
                  {
                    fieldValue?.name ? (
                      <div className="flex gap-2 items-center">
                        <img src={URL.createObjectURL(fieldValue)} alt="eventImage" className="h-[60px] max-w-[200px]" />
                        <span className="w-[150px] text-ellipsis overflow-hidden whitespace-nowrap text-sm">
                          {fieldValue.name}
                        </span>
                      </div>
                    ) : (
                      <span>Upload Media</span>
                    )
                  }
                </div>

                {errors.eventImage && touched.eventImage ? (
                  <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                    *{errors.eventImage}
                  </div>
                ) : null}

                <div className="flex flex-row justify-end w-[80px] h-[28px] xl:h-[40px] xl:w-[140px]">
                  <button
                    type="button"
                    onClick={() =>
                      document.querySelector(".input-field").click()
                    }
                    className="text-[12px] w-full flex flex-row justify-center items-center  bg-[#060606] xs:text-[0.9rem] text-white px-[0.8rem] xs:px-[1rem] py-[0.5rem]"
                  >
                    Browse
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Addevent;
