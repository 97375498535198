
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";


const firebaseConfig = {

    apiKey: "AIzaSyDnRGbaAio-3nLYvNJYuCeSsLEzHXkqz_s",
  
    authDomain: "flo-design.firebaseapp.com",
  
    projectId: "flo-design",
  
    storageBucket: "flo-design.appspot.com",
  
    messagingSenderId: "195467820203",
  
    appId: "1:195467820203:web:5ff5c27b223419187f4bee",
  
    measurementId: "G-KPJSB088F5"
  
  };
  
  
  


const app = initializeApp(firebaseConfig);
console.log(app);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
export default app;