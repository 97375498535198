import React, { useEffect, useState } from 'react'
import { Icon } from "@iconify/react";

import DataTable from "react-data-table-component";
import media from "../assets/updateimg.png";
import axios from "axios";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const Sendupdates = () => {
  const navigate = useNavigate();

  let message;
  const notifyA = () => toast.success(message);
  const notifyB = () => toast.error(message);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [btnloader, setbtnLoader] = useState(false);

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setImage(null);
  };

  // handle image input
  const handleImage = (e) => {
    const imagefile = e.target.files[0];
    if (imagefile) {
      setImage(imagefile);
      // setImageUrl(URL.createObjectURL(imagefile));
    }
  };

  // to add new event
  const handleSendupdate = async (e) => {
    setbtnLoader(true);

    try {
      if (_.isEmpty(title)) {
        setbtnLoader(false);
        message = "Title cannot be empty";
        return notifyB(message);
      }
      if (_.isEmpty(description)) {
        setbtnLoader(false);
        message = "Description cannot be empty";
        return notifyB(message);
      }

      if (_.isNull(image)) {
        setbtnLoader(false);
        message = "Add image before sending new update";
        return notifyB(message);
      }

      if (image) {
        // Get the file extension
        const fileExtension = image.name.split(".").pop().toLowerCase();

        // List of valid image extensions
        const validExtensions = ["jpg", "jpeg", "png", "svg"];

        // Check if the file extension is valid
        if (!validExtensions.includes(fileExtension)) {
          setbtnLoader(false);
          message = "Only JPG, JPEG, PNG and SVG file accepting here.";
          return notifyB(message);
        }
      }

      const formdata = new FormData();
      formdata.append("title", title);
      formdata.append("description", description);
      formdata.append("image", image);

      const result = await axios.post(
        "http://localhost:4000/sendupdate",
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (
        result.status === 400 ||
        result.status === 404 ||
        result.status === 409 ||
        result.status === 500
      ) {
        setbtnLoader(false);
        message = "Oops! Sending new updates failed try again.";
        return notifyB(message);
      }

      if (result.status === 201) {
        if (_.isEmpty(result)) {
          setbtnLoader(false);
          message = "Oops! Something went wrong";
          return notifyB(message);
        }

        setbtnLoader(false);
        getallupdates();
        message = "Succesfully sended update to users";
        resetForm();
        return notifyA(message);
      }
    } catch (error) {
      setbtnLoader(false);
      message = "Oops! Sending new update failed try after some time.";
      return notifyB(message);
    }
  };

  // useEffect(() => {
  //     getallupdates();
  // }, [])

  const columns = [
    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>#</p>
      ),
      cell: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Title
        </p>
      ),
      selector: (row) => row.title,
    },
    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Description
        </p>
      ),
      selector: (row) => row.description,
    },
    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Created Timestamp
        </p>
      ),
      selector: (row) => row.created_At,
      grow: 2,
    },
    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Status
        </p>
      ),
      cell: (row) => (
        <div className='my-2'>
          <button className='p-[10px] px-[30px] bg-[#E2FBD7] text-[14px] font-[600] text-[#34B53A]'>
            Sent
          </button>
        </div>
      ),
    },
    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Media
        </p>
      ),
      selector: (row) => (
        <img src={row.image} alt='' className='w-[123px] h-[48px]' />
      ),
    },
    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Actions
        </p>
      ),
      cell: (row) => (
        <div className='flex gap-5'>
          <div className='delete cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='33'
              viewBox='0 0 32 33'
              fill='none'>
              <path
                d='M9.33203 28.5C8.5987 28.5 7.97114 28.2391 7.44936 27.7173C6.9267 27.1947 6.66536 26.5667 6.66536 25.8333V8.5C6.28759 8.5 5.9707 8.37244 5.7147 8.11733C5.45959 7.86133 5.33203 7.54444 5.33203 7.16667C5.33203 6.78889 5.45959 6.472 5.7147 6.216C5.9707 5.96089 6.28759 5.83333 6.66536 5.83333H11.9987C11.9987 5.45556 12.1267 5.13867 12.3827 4.88267C12.6378 4.62756 12.9543 4.5 13.332 4.5H18.6654C19.0431 4.5 19.36 4.62756 19.616 4.88267C19.8711 5.13867 19.9987 5.45556 19.9987 5.83333H25.332C25.7098 5.83333 26.0263 5.96089 26.2814 6.216C26.5374 6.472 26.6654 6.78889 26.6654 7.16667C26.6654 7.54444 26.5374 7.86133 26.2814 8.11733C26.0263 8.37244 25.7098 8.5 25.332 8.5V25.8333C25.332 26.5667 25.0711 27.1947 24.5494 27.7173C24.0267 28.2391 23.3987 28.5 22.6654 28.5H9.33203ZM11.9987 21.8333C11.9987 22.2111 12.1267 22.5276 12.3827 22.7827C12.6378 23.0387 12.9543 23.1667 13.332 23.1667C13.7098 23.1667 14.0267 23.0387 14.2827 22.7827C14.5378 22.5276 14.6654 22.2111 14.6654 21.8333V12.5C14.6654 12.1222 14.5378 11.8053 14.2827 11.5493C14.0267 11.2942 13.7098 11.1667 13.332 11.1667C12.9543 11.1667 12.6378 11.2942 12.3827 11.5493C12.1267 11.8053 11.9987 12.1222 11.9987 12.5V21.8333ZM17.332 21.8333C17.332 22.2111 17.46 22.5276 17.716 22.7827C17.9711 23.0387 18.2876 23.1667 18.6654 23.1667C19.0431 23.1667 19.36 23.0387 19.616 22.7827C19.8711 22.5276 19.9987 22.2111 19.9987 21.8333V12.5C19.9987 12.1222 19.8711 11.8053 19.616 11.5493C19.36 11.2942 19.0431 11.1667 18.6654 11.1667C18.2876 11.1667 17.9711 11.2942 17.716 11.5493C17.46 11.8053 17.332 12.1222 17.332 12.5V21.8333Z'
                fill='#B53434'
              />
            </svg>
          </div>

          <div className='edit cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='33'
              viewBox='0 0 32 33'
              fill='none'>
              <path
                d='M25.7333 12.4003L20.0667 6.80033L21.9333 4.93366C22.4444 4.42255 23.0724 4.16699 23.8173 4.16699C24.5622 4.16699 25.1898 4.42255 25.7 4.93366L27.5667 6.80033C28.0778 7.31144 28.3444 7.92833 28.3667 8.65099C28.3889 9.37366 28.1444 9.9901 27.6333 10.5003L25.7333 12.4003ZM23.8 14.367L9.66667 28.5003H4V22.8337L18.1333 8.70033L23.8 14.367Z'
                fill='#060606'
              />
            </svg>
          </div>
        </div>
      ),
    },
  ];

  const getallupdates = async () => {
    setLoader(true);
    try {
      const result = await axios.get("http://localhost:4000/getupdates");

      if (
        result.status === 400 ||
        result.status === 404 ||
        result.status === 409 ||
        result.status === 500
      ) {
        setLoader(false);
        return console.log("no updates available");
      }

      setLoader(false);
      setData(result.data.response.updates);
    } catch (error) {
      setLoader(false);
      console.log("Something went wrong while finding updates");
      return console.log("updates fetched failed");
    }
  };

  const sampleData = [
    {
      title: "Paints",
      description: "shop",
      created_At: "2022-10-10",
      status: "Sent",
      image:
        "https://res.cloudinary.com/deh78ntmd/image/upload/v1698224510/Rectangle_31_sd4bki.png",
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: "bold",
        border: "none",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",

        fontSize: "20px",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        fontWeight: "800",
        height: "75px",
      },
    },
    header: {
      style: {},
    },
    rows: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",
        backgroundColor: "transparent",

        marginBottom: "-1px",

        fontSize: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        fontWeight: "600",
        overflow: "hidden",
        height: "75px",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "10px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
        borderCollapse: "collapse",
      },
    },
    headCells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    cells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
  };


  console.log("send updates viewed");
  const [selectImg, setSelectImg] = useState();

  return (
    <div className='bg-[#F6F6F6] px-[1rem] py-[2rem] lg:px-[2.5rem] lg:py-[4rem]'>
      <div className='flex flex-col gap-[1.5rem]'>
        <div
          style={{ boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
          className='adduser-form bg-[#FFF] p-[1rem] lg:p-[1.56rem] '>
          <div className='top flex justify-between items-center '>
            <p className='text-[20px] md:text-[32px] font-[600] font-poppins tracking-[-0.2px] md:tracking-[-0.32px]'>
              Send Updates
            </p>

            {btnloader ? (
              <ThreeDots
                height='80'
                width='80'
                radius='9'
                color='#0074be'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            ) : (
              <button
                className='text-[12px] md:text-[16px] font-[700] text-[#FFF] bg-[#000] font-poppins tracking-[1.76px] px-[16px] py-[8px] md:px-[54px] md:py-[20px]'
                onClick={(e) => handleSendupdate(e)}>
                Send
              </button>
            )}
          </div>

          <div className='bottom my-6 pb-5'>
            <form action=''>
              <div className='title'>
                <input
                  type='text'
                  placeholder='Title'
                  className='border-[1px] border-[#808080]  bg-transparent text-[20px] text-[#808080] font-poppins  p-[24px] w-full my-2 outline-none'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className='description'>
                <textarea
                  placeholder='Description'
                  rows={3}
                  className='border-[1px] border-[#808080]  bg-transparent text-[20px] text-[#808080] font-poppins  p-[24px] w-full my-2 outline-none'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div
                style={{ border: "1px solid  #808080" }}
                className='flex justify-between items-center  px-[1rem] py-[1rem] lg:p-[1.5rem] '>
                <div className='flex flex-row  justify-center gap-3 xl:gap-[8rem]'>
                  {selectImg?.name && (
                    <img
                      src={(selectImg && URL.createObjectURL(selectImg)) || ""}
                      alt=''
                      className='w-[4rem] h-[2rem] lg:h-[4rem] lg:w-[8rem] rounded-[5px]'
                    />
                  )}

                  <div className='flex items-center gap-[0.5rem] xl:gap-[1rem]'>
                    {selectImg?.name ? (
                      <>
                        <h3 className='text-[0.7rem] lg:text-[1.2rem] text-black font-[700] '>
                          {selectImg && selectImg.name.substring(0, 5) + ".png"}
                          {/* {selectImg?.name.length > 30
                          ? selectImg.name.substring(0, 5) + "..."
                          : selectImg.name} */}
                        </h3>
                        <Icon
                          onClick={() => setSelectImg(null)}
                          icon='akar-icons:cross'
                          className='text-[0.8rem] lg:text-[1.5rem]  cursor-pointer hover:bg-[#3D4A5C]/[0.3]'
                        />
                      </>
                    ) : (
                      <h3 className='text-[1rem] text-[#060606] font-bold font-poppins'>
                        Upload Image
                      </h3>
                    )}
                  </div>
                </div>
                <button
                  type='button'
                  className='text-[0.8rem] font-semibold text-white bg-[#060606] py-[0.5rem] px-[2rem]  lg:py-[1rem] lg:px-[3rem]
            '
                  onClick={() =>
                    document.querySelector(".input-field").click()
                  }>
                  Browse
                </button>
                <input
                  type='file'
                  accept='image/*'
                  className='input-field'
                  hidden
                  onChange={({ target: { files } }) => {
                    if (files[0]) {
                      setSelectImg(files[0]);
                    }
                  }}
                />
              </div>
            </form>
          </div>
        </div>

        <div
          style={{ boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
          className='bg-[#fff] flex flex-col '>
          <div  className='h-[75px] lg:h-[90px] flex items-center px-[1rem] lg:px-[1.5rem] '>
            <p className='text-[20px] md:text-[32px] font-[600] font-poppins tracking-[-0.32px]'>
              All Updates
            </p>
          </div>

          <hr className='border-[1px] border-b-[#e0e0e0] ' />
          <div className=''>
            {loader ? (
              <div className='w-full h-[400px] flex justify-center items-center'>
                {" "}
                <ThreeDots
                  height='80'
                  width='130'
                  radius='9'
                  color='#0074be'
                  ariaLabel='three-dots-loading'
                  wrapperStyle={{}}
                  wrapperClassName=''
                  visible={true}
                />{" "}
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={sampleData}
                customStyles={customStyles}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sendupdates